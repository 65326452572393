import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import { Component12Frame17 } from '../Component12Frame17/Component12Frame17';
import { Group9Icon } from './Group9Icon';
import classes from './MenuGroup14.module.css';
import { NounAttendant550573OptimizedIc } from './NounAttendant550573OptimizedIc';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounAttendant550573Optimized?: ReactNode;
  };
}
/* @figmaId 357:1086 */
export const MenuGroup14: FC<Props> = memo(function MenuGroup14(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.group9}>
        <Group9Icon className={classes.icon2} />
      </div>
      <Component12Frame17
        className={classes.component12Frame17}
        swap={{
          nounAttendant550573Optimized: props.swap?.nounAttendant550573Optimized || (
            <NounAttendant550573OptimizedIc className={classes.icon} />
          ),
        }}
      />
    </div>
  );
});
