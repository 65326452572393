import { memo } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';


import resets from '../../../_resets.module.css';
import { MingcuteNavigationFillIcon } from './MingcuteNavigationFillIcon';
import classes from './OverlayMenu.module.css';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';
import { Vector3Icon } from './Vector3Icon';
import { Vector4Icon } from './Vector4Icon';

interface Props {
  className?: string;
}
/* @figmaId 346:322 */
export const OverlayMenu: FC<Props> = memo(function OverlayMenu(props = {}) {

  var fixed = document.getElementById('fixed');

  if(fixed)
    fixed.addEventListener('touchmove', function(e) {
        e.preventDefault();
  }, false);

  const navigate = useNavigate();

  const goToMission = () => {
    navigate('/mission');
    window.scroll(0, 0);
  };

  const goToProjects = () => {
    navigate('/projects');
    window.scroll(0, 0);
  };

  const goToBeSponsor = () => {
    navigate('/beSponsor');
    window.scroll(0, 0);
  };

  const goToContact = () => {
    navigate('/contact');
    window.scroll(0, 0);
  };

  const goToBeVolunteer = () => {
    navigate('/beVolunteer');
    window.scroll(0, 0);
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`} id='fixed'>
      <div className={classes.frame42} onClick={goToMission}>
        <button className={classes.misyonumuz}>Misyonumuz</button>
      </div>
      <div className={classes.frame46} onClick={goToBeVolunteer}>
        <button className={classes.gonulluOl}>Gönüllü Ol</button>
        <div className={classes.mingcuteNavigationFill}>
          <MingcuteNavigationFillIcon className={classes.icon} />
        </div>
      </div>
      <div className={classes.frame43} onClick={goToProjects}>
        <button className={classes.projelerimiz}>Projelerimiz</button>
      </div>
      <div className={classes.frame44} onClick={goToBeSponsor}>
        <button className={classes.sponsorOl}>Sponsor Ol</button>
      </div>
      <div className={classes.frame45} onClick={goToContact}>
        <button className={classes.iletisim}>İletişim</button>
      </div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon2} />
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon3} />
      </div>
      <div className={classes.vector3}>
        <Vector3Icon className={classes.icon4} />
      </div>
      <div className={classes.vector4}>
        <Vector4Icon className={classes.icon5} />
      </div>
    </div>
  );
});
