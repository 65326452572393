import { memo, SVGProps } from 'react';

const Vector3Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 44 1' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M-2.66667 0C-2.66667 1.47276 -1.47276 2.66667 0 2.66667C1.47276 2.66667 2.66667 1.47276 2.66667 0C2.66667 -1.47276 1.47276 -2.66667 0 -2.66667C-1.47276 -2.66667 -2.66667 -1.47276 -2.66667 0ZM41.3333 0C41.3333 1.47276 42.5272 2.66667 44 2.66667C45.4728 2.66667 46.6667 1.47276 46.6667 0C46.6667 -1.47276 45.4728 -2.66667 44 -2.66667C42.5272 -2.66667 41.3333 -1.47276 41.3333 0ZM0 0.5L44 0.5V-0.5L0 -0.5L0 0.5Z'
      fill='#BABABA'
    />
  </svg>
);

const Memo = memo(Vector3Icon);
export { Memo as Vector3Icon };
