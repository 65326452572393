import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Component12Frame17.module.css';
import { NounAttendant550573OptimizedIc } from './NounAttendant550573OptimizedIc';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounAttendant550573Optimized?: ReactNode;
  };
}
/* @figmaId 357:967 */
export const Component12Frame17: FC<Props> = memo(function Component12Frame17(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.dezavantajlGruplarNDesteklenme}>
        <div className={classes.textBlock}>Dezavantajlı Grupların</div>
        <div className={classes.textBlock2}>Desteklenmesi</div>
      </div>
      <div className={classes.yapayZekaVeVeriBilimiImkanlarI}>
        Yapay Zeka ve Veri bilimi imkanları ile dezavantajlı gruplara fayda sağlamak ve gündelik yaşama entegre
        olmalarını kolaylaştırmak.
      </div>
      <div className={classes.nounAttendant550573Optimized}>
        {props.swap?.nounAttendant550573Optimized || <NounAttendant550573OptimizedIc className={classes.icon} />}
      </div>
    </div>
  );
});
