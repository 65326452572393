import { memo, SVGProps } from 'react';

const Vector3Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 30 1' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M-2.66667 0C-2.66667 1.47276 -1.47276 2.66667 0 2.66667C1.47276 2.66667 2.66667 1.47276 2.66667 0C2.66667 -1.47276 1.47276 -2.66667 0 -2.66667C-1.47276 -2.66667 -2.66667 -1.47276 -2.66667 0ZM27.3333 0C27.3333 1.47276 28.5272 2.66667 30 2.66667C31.4728 2.66667 32.6667 1.47276 32.6667 0C32.6667 -1.47276 31.4728 -2.66667 30 -2.66667C28.5272 -2.66667 27.3333 -1.47276 27.3333 0ZM0 0.5H30V-0.5H0V0.5Z'
      fill='#BABABA'
    />
  </svg>
);

const Memo = memo(Vector3Icon);
export { Memo as Vector3Icon };
