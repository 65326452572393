import { memo, useState } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Twitter_Property1Default.module.css';
import { VectorIcon } from './VectorIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 116:702 */
export const Twitter_Property1Default: FC<Props> = memo(function Twitter_Property1Default(props = {}) {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <div className={classes.vector}>
        <VectorIcon className={classes.icon} isHovered={isHovered}/>
      </div>
    </div>
  );
});
