import { memo, useState } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Kesfet_Property1Default.module.css';
import { MingcuteNavigationFillIcon } from './MingcuteNavigationFillIcon';
import { useNavigate } from 'react-router-dom';

interface SwapProps {
  mingcuteNavigationFill?: ReactNode;
}

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: SwapProps;
}

export const Kesfet_Property1Default: FC<Props> = memo(function Kesfet_Property1Default(props = {}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    console.log("mouse is inside");
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate('/beVolunteer');
    window.scroll(0,0);
  }

  return (
    <div
      className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      <div className={classes.gonulluOl}>Gönüllü Ol</div>
      <div className={classes.mingcuteNavigationFill}>
        {/* {props.swap?.mingcuteNavigationFill || (
          <MingcuteNavigationFillIcon className={classes.icon} isHovered={isHovered} />
        )} */}
        <MingcuteNavigationFillIcon className={classes.icon} isHovered={isHovered} />
      </div>
    </div>
  );
});
