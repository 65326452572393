import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import { Component12Frame18 } from '../Component12Frame18/Component12Frame18';
import { Group10Icon } from './Group10Icon';
import classes from './MenuGroup15.module.css';
import { NounEquality4831999OptimizedIc } from './NounEquality4831999OptimizedIc';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounEquality4831999Optimized?: ReactNode;
  };
}
/* @figmaId 357:1092 */
export const MenuGroup15: FC<Props> = memo(function MenuGroup15(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.group10}>
        <Group10Icon className={classes.icon2} />
      </div>
      <Component12Frame18
        className={classes.component12Frame18}
        swap={{
          nounEquality4831999Optimized: props.swap?.nounEquality4831999Optimized || (
            <NounEquality4831999OptimizedIc className={classes.icon} />
          ),
        }}
      />
    </div>
  );
});
