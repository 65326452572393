import { memo, useState } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import { IcRoundDownloadIcon } from './IcRoundDownloadIcon';
import classes from './TuzuguIndir_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 153:105 */
export const TuzuguIndir_Property1Default: FC<Props> = memo(function TuzuguIndir_Property1Default(props = {}) {

  
  const [isClicked, setIsClicked] = useState(false);

  async function downloadAgreement(){
    if (!isClicked) {
      // Perform your button click logic
      console.log('Button clicked!');
      
      // Update the clicked state

      try {
      window.open('https://ronobt.com.tr/res/YZVBD-Tüzük.docx');
      setIsClicked(true);
      } catch (error) {
        window.alert("There was an unexpected error!") // Handle any error that occurs during the request
      }
    }
  }

  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`} onClick={downloadAgreement}>
      {/* {props.text?.adSoyad != null ? props.text?.adSoyad : <div className={classes.adSoyad}>Ad Soyad</div>} */}
      <div className={classes.icRoundDownload}>
        <IcRoundDownloadIcon className={classes.icon} />
      </div>
      <div className={classes.tuzuguIndir}>Tüzüğü İndir </div>
    </div>
  );
});
