import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import { Component12Frame14 } from '../Component12Frame14/Component12Frame14';
import { Group8Icon } from './Group8Icon';
import classes from './MenuGroup13.module.css';
import { NounDog4432245OptimizedIcon } from './NounDog4432245OptimizedIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounDog4432245Optimized?: ReactNode;
  };
}
/* @figmaId 357:1079 */
export const MenuGroup13: FC<Props> = memo(function MenuGroup13(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.group8}>
        <Group8Icon className={classes.icon2} />
      </div>
      <Component12Frame14
        className={classes.component12Frame14}
        swap={{
          nounDog4432245Optimized: props.swap?.nounDog4432245Optimized || (
            <NounDog4432245OptimizedIcon className={classes.icon} />
          ),
        }}
      />
    </div>
  );
});
