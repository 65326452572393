import { memo, SVGProps } from 'react';

const Vector1Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 65 1' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M-2.66667 0C-2.66667 1.47276 -1.47276 2.66667 0 2.66667C1.47276 2.66667 2.66667 1.47276 2.66667 0C2.66667 -1.47276 1.47276 -2.66667 0 -2.66667C-1.47276 -2.66667 -2.66667 -1.47276 -2.66667 0ZM62.3333 0C62.3333 1.47276 63.5272 2.66667 65 2.66667C66.4728 2.66667 67.6667 1.47276 67.6667 0C67.6667 -1.47276 66.4728 -2.66667 65 -2.66667C63.5272 -2.66667 62.3333 -1.47276 62.3333 0ZM0 0.5H65V-0.5H0V0.5Z'
      fill='#BABABA'
    />
  </svg>
);

const Memo = memo(Vector1Icon);
export { Memo as Vector1Icon };
