import { memo, SVGProps } from 'react';

const Group19Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 10 1018' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM2.5 5C2.5 6.38071 3.61929 7.5 5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5Z'
      fill='#ED1B24'
    />
    <path
      d='M10 125C10 127.761 7.76142 130 5 130C2.23858 130 0 127.761 0 125C0 122.239 2.23858 120 5 120C7.76142 120 10 122.239 10 125ZM2.5 125C2.5 126.381 3.61929 127.5 5 127.5C6.38071 127.5 7.5 126.381 7.5 125C7.5 123.619 6.38071 122.5 5 122.5C3.61929 122.5 2.5 123.619 2.5 125Z'
      fill='#ED1B24'
    />
    <path
      d='M10 221C10 223.761 7.76142 226 5 226C2.23858 226 0 223.761 0 221C0 218.239 2.23858 216 5 216C7.76142 216 10 218.239 10 221ZM2.5 221C2.5 222.381 3.61929 223.5 5 223.5C6.38071 223.5 7.5 222.381 7.5 221C7.5 219.619 6.38071 218.5 5 218.5C3.61929 218.5 2.5 219.619 2.5 221Z'
      fill='#ED1B24'
    />
    <path
      d='M10 365C10 367.761 7.76142 370 5 370C2.23858 370 0 367.761 0 365C0 362.239 2.23858 360 5 360C7.76142 360 10 362.239 10 365ZM2.5 365C2.5 366.381 3.61929 367.5 5 367.5C6.38071 367.5 7.5 366.381 7.5 365C7.5 363.619 6.38071 362.5 5 362.5C3.61929 362.5 2.5 363.619 2.5 365Z'
      fill='#ED1B24'
    />
    <path
      d='M10 557C10 559.761 7.76142 562 5 562C2.23858 562 0 559.761 0 557C0 554.239 2.23858 552 5 552C7.76142 552 10 554.239 10 557ZM2.5 557C2.5 558.381 3.61929 559.5 5 559.5C6.38071 559.5 7.5 558.381 7.5 557C7.5 555.619 6.38071 554.5 5 554.5C3.61929 554.5 2.5 555.619 2.5 557Z'
      fill='#ED1B24'
    />
    <path
      d='M10 461C10 463.761 7.76142 466 5 466C2.23858 466 0 463.761 0 461C0 458.239 2.23858 456 5 456C7.76142 456 10 458.239 10 461ZM2.5 461C2.5 462.381 3.61929 463.5 5 463.5C6.38071 463.5 7.5 462.381 7.5 461C7.5 459.619 6.38071 458.5 5 458.5C3.61929 458.5 2.5 459.619 2.5 461Z'
      fill='#ED1B24'
    />
    <path
      d='M10 677C10 679.761 7.76142 682 5 682C2.23858 682 0 679.761 0 677C0 674.239 2.23858 672 5 672C7.76142 672 10 674.239 10 677ZM2.5 677C2.5 678.381 3.61929 679.5 5 679.5C6.38071 679.5 7.5 678.381 7.5 677C7.5 675.619 6.38071 674.5 5 674.5C3.61929 674.5 2.5 675.619 2.5 677Z'
      fill='#ED1B24'
    />
    <path
      d='M10 821C10 823.761 7.76142 826 5 826C2.23858 826 0 823.761 0 821C0 818.239 2.23858 816 5 816C7.76142 816 10 818.239 10 821ZM2.5 821C2.5 822.381 3.61929 823.5 5 823.5C6.38071 823.5 7.5 822.381 7.5 821C7.5 819.619 6.38071 818.5 5 818.5C3.61929 818.5 2.5 819.619 2.5 821Z'
      fill='#ED1B24'
    />
    <path
      d='M10 1013C10 1015.76 7.76142 1018 5 1018C2.23858 1018 0 1015.76 0 1013C0 1010.24 2.23858 1008 5 1008C7.76142 1008 10 1010.24 10 1013ZM2.5 1013C2.5 1014.38 3.61929 1015.5 5 1015.5C6.38071 1015.5 7.5 1014.38 7.5 1013C7.5 1011.62 6.38071 1010.5 5 1010.5C3.61929 1010.5 2.5 1011.62 2.5 1013Z'
      fill='#ED1B24'
    />
  </svg>
);

const Memo = memo(Group19Icon);
export { Memo as Group19Icon };
