import { memo, SVGProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderSubject_Property1Default } from '../HeaderSubject_Property1Default/HeaderSubject_Property1Default';
import { UyeOlButonu_Property1Default } from '../UyeOlButonu_Property1Default/UyeOlButonu_Property1Default';
import { Vector2Icon2 } from '../../../Mobile/Shared/Vector2Icon2';
import { Vector3Icon } from '../Vector3Icon';
import { Vector4Icon } from '../Vector4Icon';
import classes from './Header.module.css';

const Header = (props: SVGProps<SVGSVGElement>) => {

  const navigate = useNavigate();

function goToMain() {
  navigate("/");
  window.scroll(0, 0);
}

return (
      <div className={classes.header}>
        <HeaderSubject_Property1Default className={classes.headerSubject} navigateTo={'/mission'}/>
        <HeaderSubject_Property1Default
          className={classes.frame4}
          text={{
            misyonumuz: <div className={classes.misyonumuz}><div>Sponsor Ol</div></div>,
          }}
          navigateTo={'/beSponsor'}
        />
        <HeaderSubject_Property1Default
          className={classes.frame5}
          text={{
            misyonumuz: <div className={classes.misyonumuz2}><div>İletişim</div></div>,
          }}
          navigateTo={'/contact'}
        />
        <div className={classes.whatsAppImage2023524At17571} onClick={goToMain}></div>
        <HeaderSubject_Property1Default
          className={classes.headerSubject2}
          text={{
            misyonumuz: <div className={classes.misyonumuz3}><div>Projelerimiz</div></div>,
          }}
          navigateTo={'/projects'}
        />
        <UyeOlButonu_Property1Default className={classes.uyeOlButonu} />

        <div className={classes.vector22}>
          <Vector2Icon2 className={classes.icon5} />
        </div>
        <div className={classes.vector3}>
          <Vector3Icon className={classes.icon6} />
        </div>
        <div className={classes.vector4}>
          <Vector4Icon className={classes.icon7} />
        </div>
      </div>

      );
        };
      const Memo = memo(Header);
      export { Memo as Header };