import { memo, SVGProps } from 'react';
import { Facebook_Property1Default } from '../Facebook_Property1Default/Facebook_Property1Default';
import { Linkedin_Property1Default } from '../Linkedin_Property1Default/Linkedin_Property1Default';
import { Twitter_Property1Default } from '../Twitter_Property1Default/Twitter_Property1Default';
import { VectorIcon } from '../VectorIcon';
import { WhatsappImage2023524At17571Ico } from '../WhatsappImage2023524At17571Ico';
import { Youtube_Property1Default } from '../Youtube_Property1Default/Youtube_Property1Default';
import classes from './Footer.module.css';

const Footer = (props: SVGProps<SVGSVGElement>) => (
<div className={classes.end}>
        <div className={classes.whatsAppImage2023524At175712}>
          <WhatsappImage2023524At17571Ico className={classes.icon9} />
        </div>
        <div className={classes.frame12}>
          <div className={classes.vector}>
            <VectorIcon className={classes.icon10} />
          </div>
          <div className={classes.infoYzvbdCom}>info@aistudio.com.tr</div>
        </div>
        <Linkedin_Property1Default className={classes.linkedin} />
        <Facebook_Property1Default className={classes.facebook} />
        <Twitter_Property1Default className={classes.twitter} />
        <Youtube_Property1Default className={classes.youtube} />
        <div className={classes.dernekMerkeziAnkaraTurkiye}>
          <p className={classes.labelWrapper2}>
            <span className={classes.label4}>Dernek Merkezi </span>
            <span className={classes.label5}>Ankara/Türkiye</span>
          </p>
        </div>
        <div className={classes.iletisimIcin}>İletişim için</div>
        <div className={classes.yZVBDYiTakipEt}>YZVBD’yi Takip Et!</div>
      </div>

);

const Memo = memo(Footer);
export { Memo as Footer };