import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { Ellipse1Icon } from './Ellipse1Icon';
import { Ellipse2Icon } from './Ellipse2Icon';
import { Ellipse3Icon } from './Ellipse3Icon';
import { Ellipse4Icon } from './Ellipse4Icon';
import { Ellipse5Icon } from './Ellipse5Icon';
import { Ellipse6Icon } from './Ellipse6Icon';
import { Ellipse7Icon } from './Ellipse7Icon';
import { Ellipse8Icon } from './Ellipse8Icon';
import { Ellipse9Icon } from './Ellipse9Icon';
import classes from './Misyonumuz.module.css';
import { NounMissions5673219OptimizedIc } from './NounMissions5673219OptimizedIc';
import { TuzuguIndir_Property1Default } from './TuzuguIndir_Property1Default/TuzuguIndir_Property1Default';

interface Props {
  className?: string;
}
/* @figmaId 46:1398 */
export const Misyonumuz: FC<Props> = memo(function Misyonumuz(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.misyonumuz4}>Misyonumuz.</div>
      <div className={classes.yapayZekaEkoSisteminiOrtakBirC}>
        <div className={classes.textBlock}>
          {' '}
          Yapay zeka eko-sistemini ortak bir çatı altında toplayarak sivil toplum faaliyetlerinin bu alanda
          etkinleştirilmesi ve geliştirilmesini sağlamak,
        </div>
        <div className={classes.textBlock2}>
          <p></p>
        </div>
        <div className={classes.textBlock3}>
          {' '}
          Yapay Zeka konusunda çalışmalar yapan kişi ve kuruluşları dernek eko-sistemine dahil ederek destek vermek,
        </div>
        <div className={classes.textBlock4}>
          <p></p>
        </div>
        <div className={classes.textBlock5}>
          {' '}
          Analitik ve Yapay zeka konusunda çözümler geliştiren yazılımcıları, bu alanda çalışmalar yapmak isteyen kamu
          kurumları ve farklı sektörlerdeki kuruluşlar ile bir araya getirmek,
        </div>
        <div className={classes.textBlock6}>
          <p></p>
        </div>
        <div className={classes.textBlock7}>
          {' '}
          Analitik ve Yapay zeka çözümlerine ilgi duyan kurum ve kuruluşlara danışmanlık yapmak ve eğitimler düzenlemek,
        </div>
        <div className={classes.textBlock8}>
          <p></p>
        </div>
        <div className={classes.textBlock9}>
          {' '}
          Yapay zeka alanında kamusal ve sektörel ihtiyaçlara yönelik danışma ve çalışma grupları oluşturmak,
        </div>
        <div className={classes.textBlock10}>
          <p></p>
        </div>
        <div className={classes.textBlock11}>
          {' '}
          Araştırmalarında yapay zekaya dayalı yöntemleri uygulamak isteyen akademisyenlere danışmanlık ve destek
          sağlamak,
        </div>
        <div className={classes.textBlock12}>
          <p></p>
        </div>
        <div className={classes.textBlock13}>
          {' '}
          Analitik, yapay zeka, veri bilimi, makine öğrenimi, kodlama, robotik, yazılım, veri yönetimi, istatistik,
          matematik gibi alanlarda eğitimler, sınavlar ve sertifikalar sağlamak,
        </div>
        <div className={classes.textBlock14}>
          <p></p>
        </div>
        <div className={classes.textBlock15}>
          {' '}
          Analitik, yapay zeka, veri bilimi, makine öğrenimi, mühendislik, matematik, istatistik, beşeri bilimler,
          eğitim, sanat ve spor başta olmak üzere tüm bilimsel alanlarda eğitim faaliyetleri, kültürel değişim
          programları ve sosyal faaliyetler düzenlemek, projeler geliştirmek,
        </div>
        <div className={classes.textBlock16}>
          <p></p>
        </div>
        <div className={classes.textBlock17}>
          {' '}
          Doğanın ve hayvanların korunması, çevresel olumsuz etkilerin azaltılması, afet durumlarında destek olacak
          teknolojilerin geliştirilmesi, dezavantajlı grupların desteklenmesi, cinsiyet eşitsizliğinin giderilmesi gibi
          alanlarda sosyal sorumluluk projeleri geliştirmek ve bu alanlarda yapay zeka ve veri biliminin kullanımına
          yönelik faaliyetler göstermek amacı ile kurulmuştur.
        </div>
      </div>
      <div className={classes.ellipse1}>
        <Ellipse1Icon className={classes.icon} />
      </div>
      <div className={classes.ellipse2}>
        <Ellipse2Icon className={classes.icon2} />
      </div>
      <div className={classes.ellipse3}>
        <Ellipse3Icon className={classes.icon3} />
      </div>
      <div className={classes.ellipse4}>
        <Ellipse4Icon className={classes.icon4} />
      </div>
      <div className={classes.ellipse5}>
        <Ellipse5Icon className={classes.icon5} />
      </div>
      <div className={classes.ellipse6}>
        <Ellipse6Icon className={classes.icon6} />
      </div>
      <div className={classes.ellipse7}>
        <Ellipse7Icon className={classes.icon7} />
      </div>
      <div className={classes.ellipse8}>
        <Ellipse8Icon className={classes.icon8} />
      </div>
      <div className={classes.ellipse9}>
        <Ellipse9Icon className={classes.icon9} />
      </div>
      <TuzuguIndir_Property1Default className={classes.tuzuguIndir} />
      <div className={classes.nounMissions5673219Optimized}>
        <NounMissions5673219OptimizedIc className={classes.icon10} />
      </div>
      <div className={classes.frame13}>
        <div className={classes.mainpage}></div>
      </div>
      <div className={classes.detaylBilgiIcin}>Detaylı bilgi için.</div>
      <Header/>
      <Footer/>
    </div>
  );
});
