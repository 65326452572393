import { memo, SVGProps } from 'react';

const Frame36Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 40 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M17 19.9068L24.785 15.4348L17 10.9627V19.9068ZM34.34 8.23478C34.535 8.9354 34.67 9.87453 34.76 11.0671C34.865 12.2596 34.91 13.2882 34.91 14.1826L35 15.4348C35 18.6994 34.76 21.0994 34.34 22.6348C33.965 23.9764 33.095 24.841 31.745 25.2137C31.04 25.4075 29.75 25.5416 27.77 25.6311C25.82 25.7354 24.035 25.7801 22.385 25.7801L20 25.8696C13.715 25.8696 9.8 25.6311 8.255 25.2137C6.905 24.841 6.035 23.9764 5.66 22.6348C5.465 21.9342 5.33 20.995 5.24 19.8025C5.135 18.6099 5.09 17.5814 5.09 16.687L5 15.4348C5 12.1702 5.24 9.77019 5.66 8.23478C6.035 6.89317 6.905 6.02857 8.255 5.6559C8.96 5.46211 10.25 5.32795 12.23 5.23851C14.18 5.13416 15.965 5.08944 17.615 5.08944L20 5C26.285 5 30.2 5.23851 31.745 5.6559C33.095 6.02857 33.965 6.89317 34.34 8.23478Z'
      fill='#212121'
    />
  </svg>
);

const Memo = memo(Frame36Icon);
export { Memo as Frame36Icon };
