import { memo, useState } from 'react';
import type { FC } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';

import resets from '../../../_resets.module.css';
import { Group6Icon } from './Group6Icon';
import classes from './MobileSubjects_Property1Defaul.module.css';

interface Props {
  className?: string;
  classes?: {
    businessPlanBro1?: string;
    teamWorkBro1?: string;
    selectingTeamBro1?: string;
    root?: string;
  };
}
/* @figmaId 239:554 */
export const MobileSubjects_Property1Defaul: FC<Props> = memo(function MobileSubjects_Property1Defaul(props = {}) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: '#ff4950',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 35,
            lineHeight: 1,
            textAlign: 'center',
            width: 35,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: '#ff4950',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 35,
            lineHeight: 1,
            textAlign: 'center',
            width: 35,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            minWidth: 768, maxWidth: 992, itemsToShow: 3
          },
        ]}
        speed={400}
        easing="linear"
        autoplayDirection='forward'
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        
        <div className={classes.frame30}>
          <div className={classes.misyonumuz}>Misyonumuz</div>
          <div className={`${props.classes?.businessPlanBro1 || ''} ${classes.businessPlanBro1}`}></div>
          <div className={classes.yapayZekaVeVeriBilimiAlanNdaFa}>
          Yapay Zeka ve Veri Bilimi alanında faaliyet gösteren birey ve kuruluşları tek çatı altında toplamak için
          buradayız.
          </div>
        </div>
      <div className={classes.frame28}>
        <div className={classes.vizyonumuz}>Vizyonumuz</div>
        <div className={classes.sosyalSorumlulukProjeleriIleYa}>
          {' '}
          Sosyal sorumluluk projeleri ile Yapay Zekayı birleştirmek ve sosyal sorumluluk bilinci oluşturmak için
          çalışmaktayız.
        </div>
        <div className={`${props.classes?.teamWorkBro1 || ''} ${classes.teamWorkBro1}`}></div>
      </div>
      <div className={classes.frame29}>
        <div className={classes.kimlerIcin}>Kimler İçin?</div>
        <div className={classes.yapayZekaAlanNdaCalSanBireyler}>
          Yapay zeka alanında çalışan bireyler, akademisyenler, öğrenciler, girişimciler, kurum ve kuruluşlar için
          hizmet vermekteyiz.
        </div>
        <div className={`${props.classes?.selectingTeamBro1 || ''} ${classes.selectingTeamBro1}`}></div>
      </div>
      {/* <div className={classes.group6}>
        <Group6Icon className={classes.icon} />
      </div> */}
      </ReactSimplyCarousel>
    </div>
  );
});
