import { memo, SVGProps } from 'react';

const UnionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 160 142' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M73.0906 3.48559C72.7933 4.7298 71.6717 5.65502 70.3335 5.65502C69.1124 5.65502 68.0717 4.88472 67.6729 3.80465C66.1403 3.94487 64.6118 4.1276 63.0899 4.35331C56.8964 5.2698 50.3946 6.72545 45.0359 10.1167C45.1952 10.4701 45.2838 10.8621 45.2838 11.2748C45.2838 12.8364 44.0148 14.1023 42.4494 14.1023C40.8841 14.1023 39.6151 12.8364 39.6151 11.2748C39.6151 9.71321 40.8841 8.44729 42.4494 8.44729C43.1469 8.44729 43.7856 8.69862 44.2793 9.11546C46.9262 7.42422 49.7857 6.22727 52.9615 5.25973C56.606 4.14937 60.2278 3.46956 64.1499 2.94585C65.2677 2.79659 66.3884 2.66983 67.5111 2.56551C67.6437 1.12675 68.8567 0 70.3335 0C71.6939 0 72.8304 0.956116 73.1047 2.2315C74.0651 2.20582 75.0261 2.19637 75.987 2.20309C79.9823 2.23079 83.9701 2.543 87.9225 3.11958C90.9241 3.5575 93.9007 4.15659 96.8378 4.91237C97.2941 3.96073 98.2682 3.30338 99.3962 3.30338C100.962 3.30338 102.231 4.5693 102.231 6.13089C102.231 7.69248 100.962 8.9584 99.3962 8.9584C97.8337 8.9584 96.5666 7.69719 96.5618 6.1396C93.2037 5.27046 89.7911 4.60546 86.3501 4.16196C81.959 3.597 77.5196 3.36767 73.0906 3.48559Z'
      fill='url(#paint0_linear_600_800)'
    />
    <path
      d='M18.2604 29.6196C18.2604 29.0567 18.0955 28.5323 17.8113 28.0916C20.4908 25.4585 23.4967 23.158 26.7558 21.2756C28.5555 20.2364 30.4252 19.3291 32.3544 18.5656C32.8678 19.2882 33.7128 19.7599 34.6683 19.7599C36.2336 19.7599 37.5026 18.4939 37.5026 16.9323C37.5026 15.3707 36.2336 14.1048 34.6683 14.1048C33.1029 14.1048 31.8339 15.3707 31.8339 16.9323C31.8339 17.0942 31.8476 17.2528 31.8737 17.4072C27.5396 19.1305 23.5001 21.5513 19.8984 24.5185C18.8642 25.3709 17.8668 26.2695 16.9101 27.2102C16.4784 26.9451 15.9701 26.7921 15.4261 26.7921C13.8607 26.7921 12.5917 28.058 12.5917 29.6196C12.5917 30.2701 12.8119 30.8693 13.182 31.3471C11.6303 33.2935 10.2373 35.3652 9.02798 37.5357C7.80073 39.7386 6.76097 42.0507 5.9333 44.4338C5.847 44.4259 5.75957 44.4219 5.6712 44.4219C4.10584 44.4219 2.83686 45.6878 2.83686 47.2494C2.83686 48.811 4.10584 50.0769 5.6712 50.0769C7.23656 50.0769 8.50554 48.811 8.50554 47.2494C8.50554 46.2202 7.95432 45.3194 7.13033 44.8249C8.7239 40.2432 11.1214 35.9394 14.1525 32.1463C14.5354 32.3388 14.9681 32.4471 15.4261 32.4471C16.9914 32.4471 18.2604 31.1812 18.2604 29.6196Z'
      fill='url(#paint1_linear_600_800)'
    />
    <path
      d='M96.4947 96.8944C95.1535 97.1181 94.1313 98.2815 94.1313 99.683C94.1313 101.245 95.4003 102.511 96.9657 102.511C98.531 102.511 99.8 101.245 99.8 99.683C99.8 98.4194 98.9691 97.3494 97.8226 96.987C99.0767 93.953 101.597 91.4383 104.406 89.7678C109.194 86.9202 114.964 86.3512 120.403 85.8753L121.004 85.8229C123.351 85.6187 125.768 85.4085 128.164 85.0357C128.573 86.0967 129.604 86.8497 130.811 86.8497C132.376 86.8497 133.645 85.5838 133.645 84.0222C133.645 83.9507 133.643 83.8798 133.638 83.8096C134.728 83.4714 135.799 83.0702 136.841 82.5895C141.424 80.4746 145.175 76.6752 147.209 72.06C149.326 67.2592 149.536 61.7073 147.791 56.7598C148.412 56.2411 148.807 55.4622 148.807 54.5914C148.807 53.0298 147.538 51.7639 145.972 51.7639C144.407 51.7639 143.138 53.0298 143.138 54.5914C143.138 56.153 144.407 57.4189 145.972 57.4189C146.208 57.4189 146.438 57.3901 146.657 57.3358C148.097 61.5726 148.061 66.2847 146.495 70.4914C144.796 75.0587 141.328 78.9035 136.99 81.1191C135.79 81.7315 134.546 82.2219 133.273 82.6203C132.785 81.7686 131.865 81.1947 130.811 81.1947C129.319 81.1947 128.096 82.3447 127.985 83.8047C125.738 84.1503 123.461 84.3477 121.221 84.5418L120.97 84.5635C115.544 85.0343 109.532 85.5656 104.505 88.2622C101.035 90.1232 97.8876 93.1556 96.4947 96.8944Z'
      fill='url(#paint2_linear_600_800)'
    />
    <path
      d='M106.478 98.5903C107.345 98.5903 108.121 98.9784 108.641 99.59C112.797 98.2613 117.204 97.821 121.543 97.5731C122.689 97.5077 123.837 97.4565 124.986 97.4052C129.015 97.2254 133.053 97.0452 137.012 96.2538C138.512 95.9543 140.012 95.566 141.473 95.0649C141.441 94.8953 141.424 94.7204 141.424 94.5416C141.424 92.98 142.693 91.7141 144.258 91.7141C145.144 91.7141 145.934 92.1189 146.454 92.753C147.338 92.2094 148.183 91.6013 148.976 90.921C152.279 88.0859 154.458 84.2639 155.144 79.8023C155.674 76.357 155.122 72.7885 153.683 69.6245C153.451 69.6858 153.207 69.7184 152.956 69.7184C151.39 69.7184 150.121 68.4525 150.121 66.8909C150.121 65.3293 151.39 64.0634 152.956 64.0634C154.521 64.0634 155.79 65.3293 155.79 66.8909C155.79 67.7475 155.408 68.5151 154.805 69.0336C156.56 72.8589 157.055 77.2284 156.136 81.3432C155.149 85.7645 152.587 89.7553 148.998 92.5198C148.361 93.0105 147.698 93.4626 147.014 93.8787C147.066 94.0913 147.093 94.3133 147.093 94.5416C147.093 96.1032 145.824 97.3691 144.258 97.3691C143.325 97.3691 142.496 96.9185 141.98 96.2233C140.17 96.8536 138.297 97.3148 136.422 97.6436C132.623 98.3107 128.776 98.4884 124.93 98.6661C123.42 98.7359 121.91 98.8056 120.403 98.905C116.647 99.1535 112.833 99.6054 109.227 100.725C109.283 100.947 109.313 101.179 109.313 101.418C109.313 102.979 108.044 104.245 106.478 104.245C104.913 104.245 103.644 102.979 103.644 101.418C103.644 99.8562 104.913 98.5903 106.478 98.5903Z'
      fill='url(#paint3_linear_600_800)'
    />
    <path
      d='M92.0063 128.86C90.966 129.279 90.2319 130.295 90.2319 131.483C90.2319 133.045 91.5009 134.311 93.0662 134.311C94.6316 134.311 95.9006 133.045 95.9006 131.483C95.9006 129.981 94.7263 128.752 93.2435 128.661C92.8548 125.737 92.6254 122.765 93.1419 119.858C93.6568 116.96 94.9465 114.1 97.2155 112.156C97.2882 112.094 97.3616 112.033 97.4358 111.974C97.9244 112.373 98.549 112.612 99.2296 112.612C100.726 112.612 101.951 111.456 102.057 109.99C103.455 109.736 104.908 109.687 106.317 109.729C107.364 109.761 108.415 109.84 109.468 109.92C112.179 110.124 114.896 110.329 117.571 109.722C119.562 109.268 121.54 108.274 122.965 106.792C123.338 106.972 123.756 107.073 124.199 107.073C125.764 107.073 127.033 105.807 127.033 104.245C127.033 102.684 125.764 101.418 124.199 101.418C122.633 101.418 121.364 102.684 121.364 104.245C121.364 104.91 121.594 105.522 121.98 106.005C120.759 107.236 119.08 108.058 117.414 108.465C114.812 109.1 112.128 108.885 109.463 108.671C108.409 108.586 107.358 108.502 106.317 108.47C104.832 108.426 103.325 108.488 101.869 108.753C101.456 107.702 100.43 106.957 99.2296 106.957C97.6642 106.957 96.3952 108.223 96.3952 109.785C96.3952 110.215 96.4915 110.622 96.6638 110.987C94.2435 112.894 92.7437 115.816 92.0743 118.778C91.3245 122.099 91.5636 125.505 92.0063 128.86Z'
      fill='url(#paint4_linear_600_800)'
    />
    <path
      d='M105.327 121.83C103.974 123.148 102.994 124.938 102.759 126.805C103.988 127.114 104.898 128.225 104.898 129.547C104.898 131.108 103.629 132.374 102.064 132.374C100.499 132.374 99.2296 131.108 99.2296 129.547C99.2296 128.185 100.195 127.047 101.481 126.779C101.743 124.464 102.991 122.227 104.706 120.682C107.276 118.368 110.721 117.335 114.08 116.855C115.551 116.644 117.035 116.524 118.518 116.404C120.881 116.214 123.244 116.023 125.554 115.465C125.779 115.41 126.004 115.353 126.227 115.292C126.204 115.147 126.192 114.999 126.192 114.848C126.192 113.286 127.461 112.02 129.027 112.02C130.013 112.02 130.881 112.522 131.389 113.284C134.36 111.751 137.042 109.658 139.232 107.133C138.831 106.645 138.59 106.021 138.59 105.341C138.59 103.779 139.859 102.513 141.424 102.513C142.989 102.513 144.258 103.779 144.258 105.341C144.258 106.902 142.989 108.168 141.424 108.168C140.995 108.168 140.587 108.073 140.222 107.902C138.507 109.889 136.508 111.625 134.297 113.055C133.503 113.569 132.681 114.042 131.836 114.473C131.853 114.596 131.861 114.721 131.861 114.848C131.861 116.409 130.592 117.675 129.027 117.675C128.066 117.675 127.216 117.198 126.704 116.469C126.339 116.569 125.973 116.662 125.604 116.746C123.383 117.257 121.119 117.438 118.854 117.62H118.846C117.297 117.744 115.748 117.868 114.211 118.098C111.036 118.574 107.685 119.534 105.327 121.83Z'
      fill='url(#paint5_linear_600_800)'
    />
    <path
      d='M80.5144 116.664C80.9274 116.173 81.1761 115.539 81.1761 114.848C81.1761 113.286 79.9072 112.02 78.3418 112.02C76.7764 112.02 75.5075 113.286 75.5075 114.848C75.5075 116.409 76.7764 117.675 78.3418 117.675C78.758 117.675 79.1532 117.586 79.5092 117.425C80.9106 119.164 82.0801 121.084 83.0009 123.129C84.4244 126.286 85.222 129.741 85.3229 133.2C85.3542 134.251 85.3217 135.302 85.2229 136.347L85.2027 136.346L85.1883 136.346L85.1655 136.345L85.1515 136.345L85.1286 136.345C83.5632 136.345 82.2942 137.611 82.2942 139.173C82.2942 140.734 83.5632 142 85.1286 142C86.6939 142 87.9629 140.734 87.9629 139.173C87.9629 138.089 87.3517 137.147 86.4544 136.673C86.7339 133.933 86.5833 131.159 86.0296 128.452C85.3078 124.917 83.8893 121.5 81.8702 118.504C81.4443 117.872 80.992 117.258 80.5144 116.664Z'
      fill='url(#paint6_linear_600_800)'
    />
    <path
      d='M47.424 96.2663C47.424 97.4684 46.6721 98.4953 45.6118 98.9044C45.9186 100.166 46.3996 101.386 47.0404 102.521C49.0318 106.048 52.4819 108.44 56.346 109.538C59.6457 110.474 63.1057 110.446 66.4544 109.815L66.4542 109.785C66.4542 108.223 67.7232 106.957 69.2886 106.957C70.8539 106.957 72.1229 108.223 72.1229 109.785C72.1229 111.346 70.8539 112.612 69.2886 112.612C68.1768 112.612 67.2145 111.973 66.7502 111.044C65.1101 111.357 63.4439 111.531 61.7749 111.529C57.3682 111.524 52.9438 110.215 49.5113 107.4C46.9223 105.28 45.1275 102.313 44.3637 99.085C42.904 98.9701 41.7554 97.752 41.7554 96.2663C41.7554 94.7047 43.0244 93.4388 44.5897 93.4388C46.1551 93.4388 47.424 94.7047 47.424 96.2663Z'
      fill='url(#paint7_linear_600_800)'
    />
    <path
      d='M2.23342 62.2247C2.33529 64.9116 2.73225 67.589 3.40726 70.1918C4.33606 73.7822 5.79992 77.2467 7.73322 80.4141C9.63877 83.5362 12.0138 86.3713 14.7648 88.7884C17.4578 91.1552 20.4865 93.1442 23.7373 94.6625C26.5394 95.9734 29.4972 96.9531 32.5347 97.5421C32.6354 99.0124 33.8629 100.174 35.3623 100.174C36.9277 100.174 38.1967 98.9081 38.1967 97.3465C38.1967 95.7849 36.9277 94.519 35.3623 94.519C34.1655 94.519 33.142 95.2589 32.7264 96.3053C30.2215 95.8164 27.7626 95.0567 25.4258 94.0507C22.2482 92.6835 19.2422 90.8782 16.5719 88.6801C13.8512 86.4443 11.4635 83.8182 9.52267 80.8825C7.53384 77.8711 5.99931 74.5753 4.97208 71.1183C4.11109 68.2208 3.60827 65.2278 3.49185 62.2119C4.74015 61.9161 5.66867 60.7966 5.66867 59.4608C5.66867 57.8993 4.3997 56.6333 2.83434 56.6333C1.26898 56.6333 0 57.8993 0 59.4608C0 60.8167 0.956697 61.9497 2.23342 62.2247Z'
      fill='url(#paint8_linear_600_800)'
    />
    <path
      d='M121.798 13.2513C118.796 11.4934 115.616 10.0401 112.284 9.05535C111.814 9.96806 110.861 10.5925 109.762 10.5925C108.196 10.5925 106.927 9.32655 106.927 7.76496C106.927 6.20337 108.196 4.93745 109.762 4.93745C111.327 4.93745 112.596 6.20337 112.596 7.76496C112.596 7.78629 112.596 7.80757 112.595 7.8288C116.563 8.9918 120.329 10.8149 123.825 13.0045C127.75 15.4619 131.374 18.3801 134.764 21.5223C135.877 22.5538 136.967 23.6112 138.034 24.6899C138.379 24.5408 138.76 24.4581 139.16 24.4581C140.726 24.4581 141.995 25.724 141.995 27.2856C141.995 27.7409 141.887 28.171 141.695 28.5519C142.515 29.4523 143.323 30.3629 144.122 31.2814C147.262 34.8919 150.238 38.6662 152.671 42.7929C154.773 46.3601 156.475 50.2083 157.39 54.2527C158.851 54.3668 160 55.5852 160 57.0714C160 58.633 158.731 59.8989 157.166 59.8989C155.6 59.8989 154.331 58.633 154.331 57.0714C154.331 55.8711 155.081 54.8454 156.139 54.4351C155.106 49.9613 153.097 45.7728 150.646 41.9041C148.163 37.9838 145.212 34.3959 142.136 30.9289C141.721 30.4607 141.303 29.9949 140.882 29.5318C140.405 29.8965 139.808 30.1131 139.16 30.1131C137.595 30.1131 136.326 28.8472 136.326 27.2856C136.326 26.5831 136.583 25.9405 137.008 25.446C135.607 24.0356 134.17 22.6621 132.689 21.336C129.317 18.3171 125.71 15.5425 121.798 13.2513Z'
      fill='url(#paint9_linear_600_800)'
    />
    <path
      d='M12.5892 50.0014C12.647 50.0014 12.7044 50.0031 12.7614 50.0065C13.3872 47.8221 14.2031 45.6934 15.1913 43.6439C17.7935 38.2482 21.6349 33.4492 26.3672 29.7657C30.4166 26.6127 35.0544 24.2379 39.9872 22.83C40.0842 21.356 41.3134 20.1904 42.8154 20.1904C44.3808 20.1904 45.6497 21.4563 45.6497 23.0179C45.6497 24.5795 44.3808 25.8454 42.8154 25.8454C41.6272 25.8454 40.6097 25.116 40.1884 24.0815C37.9273 24.735 35.7277 25.5934 33.6234 26.6511C28.4191 29.2697 23.8029 33.0489 20.2947 37.6993C17.4305 41.4975 15.2827 45.8019 13.975 50.3618C14.8394 50.8461 15.4235 51.7694 15.4235 52.8289C15.4235 54.3905 14.1546 55.6564 12.5892 55.6564C11.0238 55.6564 9.75487 54.3905 9.75487 52.8289C9.75487 51.2673 11.0238 50.0014 12.5892 50.0014Z'
      fill='url(#paint10_linear_600_800)'
    />
    <path
      d='M52.793 15.6379C52.2896 15.1813 51.6208 14.903 50.8868 14.903C49.3215 14.903 48.0525 16.1689 48.0525 17.7305C48.0525 19.2921 49.3215 20.558 50.8868 20.558C52.4522 20.558 53.7212 19.2921 53.7212 17.7305C53.7212 17.3588 53.6493 17.0038 53.5186 16.6787C57.0986 14.7916 61.0265 13.555 65.051 13.0826C69.9347 12.511 74.8992 13.0524 79.5507 14.6361C81.1018 15.1643 82.6088 15.8094 84.0603 16.5615C83.9852 16.8156 83.9449 17.0845 83.9449 17.3629C83.9449 18.9245 85.2138 20.1904 86.7792 20.1904C88.3446 20.1904 89.6135 18.9245 89.6135 17.3629C89.6135 15.8013 88.3446 14.5354 86.7792 14.5354C85.9464 14.5354 85.1976 14.8936 84.679 15.4641C81.1839 13.6515 77.3744 12.4436 73.4707 11.9143C68.4052 11.2295 63.2161 11.6953 58.3551 13.2765C56.4379 13.8999 54.5728 14.6905 52.793 15.6379Z'
      fill='url(#paint11_linear_600_800)'
    />
    <path
      d='M17.2144 62.0154L17.3224 61.923C17.9825 61.3587 18.6427 60.7942 19.275 60.1986C20.6531 58.8994 21.8443 57.4315 22.5864 55.6791C23.553 53.3981 23.79 50.9568 24.0269 48.5162C24.0681 48.0924 24.1092 47.6685 24.1542 47.2456C25.6501 47.1681 26.8391 45.9335 26.8391 44.4219C26.8391 43.3137 26.2001 42.3545 25.2696 41.8908C25.5508 41.1622 25.9024 40.462 26.347 39.8067C27.3565 38.3187 28.6589 37.2537 30.1883 36.4883C33.4366 34.8643 37.4168 35.2772 40.3546 37.3745C41.3691 38.0987 42.1904 39.0325 43.0112 39.9656L43.0117 39.9662L43.0123 39.9668L43.0128 39.9674C43.4983 40.5193 43.9838 41.0714 44.5089 41.5793C45.5522 42.5884 46.7797 43.433 48.1397 43.7838C48.4516 45.0074 49.5633 45.9125 50.8868 45.9125C52.4522 45.9125 53.7212 44.6465 53.7212 43.0849C53.7212 41.5234 52.4522 40.2574 50.8868 40.2574C49.5399 40.2574 48.4124 41.1947 48.124 42.4511C47.2833 42.1709 46.496 41.6681 45.8441 41.0959C45.1348 40.4727 44.5097 39.7655 43.8846 39.0584C43.1661 38.2456 42.4476 37.4329 41.6014 36.7476C38.6333 34.3431 34.4537 33.5399 30.837 34.8466C29.1435 35.4585 27.5938 36.4656 26.3646 37.7799C25.3289 38.8894 24.5607 40.1882 24.031 41.5945L24.0048 41.5944C22.4394 41.5944 21.1705 42.8603 21.1705 44.4219C21.1705 45.6013 21.8943 46.612 22.923 47.0362C22.8828 47.4231 22.8457 47.8106 22.8085 48.1981C22.6346 50.0125 22.4606 51.8274 21.9781 53.5893C21.496 55.3518 20.6455 56.9128 19.4113 58.2649C18.5195 59.2418 17.4749 60.1333 16.4336 61.022L16.2526 61.1765C15.8545 60.9638 15.3995 60.8431 14.9162 60.8431C13.3509 60.8431 12.0819 62.109 12.0819 63.6706C12.0819 65.2322 13.3509 66.4982 14.9162 66.4982C16.4816 66.4982 17.7506 65.2322 17.7506 63.6706C17.7506 63.0525 17.5517 62.4807 17.2144 62.0154Z'
      fill='url(#paint12_linear_600_800)'
    />
    <path
      d='M30.8387 73.7099C28.3673 72.2273 26.425 69.8044 25.6598 67.0389C24.2238 66.9005 23.1012 65.6933 23.1012 64.2246C23.1012 62.663 24.3702 61.397 25.9356 61.397C27.5009 61.397 28.7699 62.663 28.7699 64.2246C28.7699 65.4374 28.0045 66.4718 26.9292 66.8734C27.2378 67.9095 27.7331 68.8909 28.3711 69.7814C29.1611 70.8847 30.1504 71.7811 31.2648 72.4818C31.7676 71.6925 32.6518 71.1687 33.6587 71.1687C35.2241 71.1687 36.493 72.4346 36.493 73.9962C36.493 74.1001 36.4874 74.2026 36.4765 74.3035C37.0411 74.3781 37.6097 74.4198 38.1765 74.4293C41.4529 74.4845 44.6102 73.5148 47.5273 72.0821C47.4293 71.7956 47.3761 71.4883 47.3761 71.1687C47.3761 69.6071 48.6451 68.3412 50.2104 68.3412C51.7758 68.3412 53.0448 69.6071 53.0448 71.1687C53.0448 72.7303 51.7758 73.9962 50.2104 73.9962C49.4226 73.9962 48.7098 73.6756 48.1961 73.1579C46.7259 73.8882 45.1998 74.5051 43.6205 74.948C41.1796 75.6328 38.564 75.8765 36.0496 75.5154C35.5463 76.3021 34.6636 76.8237 33.6587 76.8237C32.0933 76.8237 30.8244 75.5578 30.8244 73.9962C30.8244 73.8996 30.8292 73.804 30.8387 73.7099Z'
      fill='url(#paint13_linear_600_800)'
    />
    <path
      d='M33.9035 47.2494C33.602 47.2494 33.3115 47.2024 33.0389 47.1154C32.1853 48.4158 31.4933 49.8425 31.3948 51.4088C31.2888 53.0983 31.9475 54.6543 33.3129 55.674C36.1128 57.7667 39.6501 57.171 42.8186 56.3209C42.8182 56.3 42.8179 56.2791 42.8179 56.2582C42.8179 54.6966 44.0869 53.4307 45.6523 53.4307C46.7464 53.4307 47.6957 54.0491 48.1681 54.9547C50.9645 54.3988 53.8125 54.1905 56.6363 54.8432C59.9805 55.6161 63.3069 57.7135 64.7203 60.9438C65.6628 63.1006 65.7504 65.6819 64.8212 67.833C65.2716 68.3336 65.5456 68.9953 65.5456 69.721C65.5456 71.2825 64.2767 72.5485 62.7113 72.5485C61.1459 72.5485 59.877 71.2825 59.877 69.721C59.877 68.1594 61.1459 66.8934 62.7113 66.8934C63.0828 66.8934 63.4375 66.9647 63.7626 67.0943C64.6977 64.6905 64.1641 61.8055 62.5523 59.7856C60.2808 56.938 56.5782 55.7219 53.0347 55.6917C51.5035 55.6775 49.9836 55.8562 48.4846 56.1499C48.4859 56.1858 48.4866 56.2219 48.4866 56.2582C48.4866 57.8198 47.2176 59.0857 45.6523 59.0857C44.5504 59.0857 43.5954 58.4585 43.1265 57.5424C42.2332 57.785 41.3347 58.004 40.4202 58.1541C38.5626 58.4587 36.6596 58.4864 34.8626 57.8721C33.2902 57.3333 31.8314 56.369 30.9531 54.9338C29.9158 53.2343 29.9284 51.1117 30.5467 49.2662C30.8821 48.2651 31.3779 47.3457 31.9505 46.471C31.4076 45.9559 31.0692 45.2283 31.0692 44.4219C31.0692 42.8603 32.3382 41.5944 33.9035 41.5944C35.4689 41.5944 36.7379 42.8603 36.7379 44.4219C36.7379 45.9835 35.4689 47.2494 33.9035 47.2494Z'
      fill='url(#paint14_linear_600_800)'
    />
    <path
      d='M53.1306 87.4615C53.7111 84.8687 55.2418 82.4893 57.3373 80.8457C57.246 80.5681 57.1966 80.2715 57.1966 79.9634C57.1966 78.4019 58.4656 77.1359 60.0309 77.1359C61.5963 77.1359 62.8653 78.4019 62.8653 79.9634C62.8653 81.525 61.5963 82.791 60.0309 82.791C59.2325 82.791 58.5112 82.4616 57.996 81.9317C55.7578 83.7423 54.265 86.4996 54.1376 89.3801C53.9862 92.8169 55.6696 96.3293 58.5267 98.2856C60.29 99.4938 62.2962 100.105 64.3721 100.446C64.7692 99.3633 65.8115 98.5903 67.0347 98.5903C68.4354 98.5903 69.5987 99.6038 69.828 100.936L70.1139 100.952C72.3248 101.08 74.5585 101.242 76.7088 101.803C78.4857 102.266 80.1843 103.032 81.6986 104.072C83.6939 105.444 85.3743 107.237 86.5972 109.307C86.851 109.233 87.1196 109.193 87.3975 109.193C88.9629 109.193 90.2319 110.459 90.2319 112.02C90.2319 113.582 88.9629 114.848 87.3975 114.848C85.8322 114.848 84.5632 113.582 84.5632 112.02C84.5632 111.186 84.9257 110.435 85.5023 109.918C84.037 107.452 81.8673 105.417 79.3009 104.114C76.333 102.608 73.0347 102.382 69.7606 102.195C69.4221 103.379 68.3299 104.245 67.0347 104.245C65.5583 104.245 64.3456 103.119 64.2125 101.681C61.1311 101.162 58.1439 100.085 55.975 97.7216C53.4663 94.9873 52.3482 90.9563 53.1306 87.4615Z'
      fill='url(#paint15_linear_600_800)'
    />
    <path
      d='M76.3815 89.8755C74.8405 88.1409 73.7754 85.9914 73.3596 83.6974C72.7034 80.0793 73.771 76.222 76.1233 73.4045C77.0822 72.255 78.1892 71.2474 79.2946 70.2412L79.2998 70.2365L79.3018 70.2347C79.4626 70.0883 79.6234 69.9419 79.7837 69.7951C80.1326 69.9482 80.5182 70.0332 80.9238 70.0332C82.4891 70.0332 83.7581 68.7673 83.7581 67.2057C83.7581 66.7264 83.6386 66.275 83.4276 65.8795C83.6429 65.5912 83.8516 65.2967 84.0534 64.995C86.1608 61.8427 87.4304 58.1465 87.7105 54.3698C87.9793 50.7413 87.3048 47.0503 85.8164 43.7298C86.2989 43.2228 86.595 42.5375 86.595 41.7832C86.595 40.2216 85.326 38.9557 83.7606 38.9557C82.1953 38.9557 80.9263 40.2216 80.9263 41.7832C80.9263 43.3448 82.1953 44.6107 83.7606 44.6107C84.1077 44.6107 84.4403 44.5485 84.7476 44.4346C85.9738 47.2435 86.579 50.3109 86.4965 53.3828C86.3956 57.1444 85.2169 60.8431 83.1902 64.0105C82.994 64.3171 82.7894 64.6163 82.5771 64.9088C82.1118 64.5748 81.5408 64.3782 80.9238 64.3782C79.3584 64.3782 78.0894 65.6441 78.0894 67.2057C78.0894 67.8994 78.3398 68.5347 78.7554 69.0267C78.6447 69.1277 78.5338 69.2286 78.4229 69.3296C77.3519 70.3043 76.2737 71.2856 75.3333 72.3898C72.8145 75.3483 71.5349 79.2685 72.0119 83.1435C72.356 85.945 73.5736 88.6197 75.4498 90.7244C75.1313 91.1816 74.9446 91.7371 74.9446 92.336C74.9446 93.8976 76.2136 95.1635 77.779 95.1635C79.3443 95.1635 80.6133 93.8976 80.6133 92.336C80.6133 90.7744 79.3443 89.5085 77.779 89.5085C77.2708 89.5085 76.7939 89.6419 76.3815 89.8755Z'
      fill='url(#paint16_linear_600_800)'
    />
    <path
      d='M73.8859 56.3011L73.8594 56.301C72.294 56.301 71.025 57.5669 71.025 59.1285C71.025 60.6901 72.294 61.956 73.8594 61.956C75.4247 61.956 76.6937 60.6901 76.6937 59.1285C76.6937 58.0177 76.0517 57.0566 75.1177 56.5942C75.3267 54.8303 74.8685 52.9622 74.0058 51.4265C72.5217 48.7828 69.8994 47.0001 67.3856 45.429C67.1287 45.2684 66.8694 45.1081 66.6091 44.9472L66.6061 44.9453L66.6024 44.9431L66.6017 44.9426L66.601 44.9422C66.4634 44.8571 66.3254 44.7719 66.1874 44.6863C66.3554 44.325 66.4492 43.9224 66.4492 43.4979C66.4492 41.9363 65.1802 40.6704 63.6149 40.6704C62.882 40.6704 62.2141 40.9478 61.7109 41.4032C61.2402 40.9426 60.8038 40.4475 60.4146 39.9074C58.6579 37.4702 57.9563 34.5722 58.4131 31.7296C58.7511 29.6305 59.7009 27.649 61.1075 26.0646C61.5636 26.3774 62.116 26.5605 62.7113 26.5605C64.2767 26.5605 65.5456 25.2946 65.5456 23.733C65.5456 22.1714 64.2767 20.9055 62.7113 20.9055C61.1459 20.9055 59.877 22.1714 59.877 23.733C59.877 24.2464 60.0141 24.7278 60.2539 25.1428C58.9978 26.5251 58.0517 28.1819 57.5196 29.9847C56.7044 32.7468 56.9745 35.9796 58.239 38.6838C58.914 40.1284 59.8747 41.3472 60.9957 42.4152C60.8571 42.7487 60.7805 43.1143 60.7805 43.4979C60.7805 45.0595 62.0495 46.3254 63.6149 46.3254C64.2976 46.3254 64.9239 46.0846 65.4132 45.6835C65.6272 45.8171 65.8419 45.9502 66.0568 46.0835C68.1349 47.3718 70.2304 48.6709 71.8377 50.5352C73.1763 52.0873 74.0862 54.2545 73.8859 56.3011Z'
      fill='url(#paint17_linear_600_800)'
    />
    <path
      d='M77.1987 29.958C78.5709 28.5027 80.3255 27.413 82.2362 26.772C85.0806 25.8177 88.339 25.8379 91.1758 27.0414C94.0985 28.2802 96.5063 30.5689 98.0762 33.3032C99.747 36.2163 100.355 39.565 99.9691 42.8885C99.663 45.5327 98.7199 48.1271 97.3108 50.407C96.9347 50.223 96.5118 50.1197 96.0646 50.1197C94.4993 50.1197 93.2303 51.3856 93.2303 52.9472C93.2303 54.5088 94.4993 55.7748 96.0646 55.7748C97.63 55.7748 98.899 54.5088 98.899 52.9472C98.899 52.2868 98.672 51.6792 98.2916 51.198C99.6006 49.1173 100.54 46.8048 101.004 44.3917C101.716 40.6905 101.314 37.0472 99.7192 33.7161C98.2528 30.6595 95.8072 28.0082 92.7937 26.422C89.7776 24.8333 86.3047 24.4254 82.9934 25.2411C80.4279 25.8744 78.027 27.2213 76.2254 29.1474C75.8401 28.9519 75.404 28.8416 74.9421 28.8416C73.3767 28.8416 72.1078 30.1076 72.1078 31.6691C72.1078 33.2307 73.3767 34.4967 74.9421 34.4967C76.5075 34.4967 77.7764 33.2307 77.7764 31.6691C77.7764 31.026 77.5612 30.433 77.1987 29.958Z'
      fill='url(#paint18_linear_600_800)'
    />
    <path
      d='M17.7861 76.1378C19.0235 75.8342 19.9413 74.72 19.9413 73.3919C19.9413 71.8303 18.6723 70.5644 17.107 70.5644C15.5416 70.5644 14.2726 71.8303 14.2726 73.3919C14.2726 74.7482 15.2298 75.8814 16.5071 76.156C16.2162 79.8628 17.849 83.6844 20.6127 86.1774C20.8219 86.3661 21.0358 86.5476 21.254 86.722C21.1994 86.9411 21.1705 87.1702 21.1705 87.4061C21.1705 88.9677 22.4394 90.2336 24.0048 90.2336C24.8439 90.2336 25.5978 89.8699 26.1168 89.2918C28.8825 90.1708 31.8939 90.3237 34.7743 89.856C36.915 89.5079 38.9918 88.8286 40.9651 87.9285C41.485 88.5509 42.2679 88.947 43.1435 88.947C44.7089 88.947 45.9779 87.6811 45.9779 86.1195C45.9779 84.5579 44.7089 83.292 43.1435 83.292C41.5782 83.292 40.3092 84.5579 40.3092 86.1195C40.3092 86.3549 40.338 86.5836 40.3923 86.8022C36.6553 88.4924 32.4975 89.3741 28.4292 88.592C27.8577 88.482 27.2914 88.3402 26.7352 88.1673C26.8029 87.9251 26.8391 87.6698 26.8391 87.4061C26.8391 85.8445 25.5702 84.5786 24.0048 84.5786C23.1461 84.5786 22.3766 84.9596 21.8568 85.5613C20.3229 84.2858 19.1018 82.6565 18.3992 80.6987C17.8726 79.2268 17.6483 77.6693 17.7861 76.1378Z'
      fill='url(#paint19_linear_600_800)'
    />
    <path
      d='M97.1084 64.2037C97.9679 62.7621 99.2224 61.5596 100.582 60.5687C102.128 59.4433 103.818 58.5593 105.507 57.6757C106.074 57.3791 106.641 57.0824 107.203 56.7768C109.479 55.5381 111.743 54.1054 113.368 52.0509C114.908 50.0996 115.746 47.7001 115.852 45.2251C115.925 43.5049 115.637 41.7543 114.991 40.1519C115.482 39.6435 115.784 38.9521 115.784 38.1903C115.784 36.6287 114.515 35.3628 112.95 35.3628C111.384 35.3628 110.115 36.6287 110.115 38.1903C110.115 39.7519 111.384 41.0178 112.95 41.0178C113.282 41.0178 113.602 40.9606 113.898 40.8555C115.157 44.2133 114.727 48.1937 112.525 51.0941C111.009 53.0908 108.825 54.4554 106.655 55.6413C106.065 55.9641 105.468 56.276 104.871 56.5879L104.865 56.5912C103.234 57.4435 101.604 58.2959 100.103 59.3677C98.4227 60.5678 96.8882 62.0253 95.8829 63.8109C95.8063 63.8047 95.7289 63.8016 95.6507 63.8016C94.0854 63.8016 92.8164 65.0675 92.8164 66.6291C92.8164 68.1907 94.0854 69.4566 95.6507 69.4566C97.2161 69.4566 98.485 68.1907 98.485 66.6291C98.485 65.5993 97.9332 64.6981 97.1084 64.2037Z'
      fill='url(#paint20_linear_600_800)'
    />
    <path
      d='M83.7934 82.9697C83.8168 80.4288 84.3455 77.8904 85.3528 75.5579C84.571 75.0545 84.0534 74.1778 84.0534 73.1804C84.0534 71.6189 85.3224 70.3529 86.8877 70.3529C88.4531 70.3529 89.7221 71.6189 89.7221 73.1804C89.7221 74.742 88.4531 76.008 86.8877 76.008C86.7705 76.008 86.6549 76.0009 86.5414 75.9871C86.1449 76.8922 85.8258 77.8308 85.5854 78.7952C84.7601 82.1036 84.8333 85.4649 86.0094 88.8589C86.3844 89.9412 86.8811 90.9943 87.3782 92.0481C88.0341 93.4389 88.6907 94.831 89.0692 96.2933C90.4881 96.4479 91.5923 97.6473 91.5923 99.1039C91.5923 100.666 90.3233 101.931 88.7579 101.931C87.1926 101.931 85.9236 100.666 85.9236 99.1039C85.9236 97.8763 86.7078 96.8315 87.8037 96.4407C87.5138 95.3653 87.0652 94.3252 86.5949 93.3129C86.4857 93.0779 86.3753 92.8432 86.2649 92.6084L86.2639 92.6062C85.5917 91.177 84.9187 89.7458 84.4875 88.2219C84.008 86.5198 83.7758 84.7372 83.7934 82.9697Z'
      fill='url(#paint21_linear_600_800)'
    />
    <path
      d='M91.297 83.2089C91.297 81.7417 92.4172 80.5355 93.851 80.3951C95.6822 76.7031 99.4987 74.3227 103.434 73.324C105.398 72.8261 107.389 72.6113 109.391 72.5271C109.687 71.2805 110.809 70.3529 112.149 70.3529C113.482 70.3529 114.6 71.2708 114.903 72.5075C116.494 72.5291 118.084 72.5453 119.666 72.4805C124.302 72.2891 129.254 71.2291 132.532 67.6941C134.143 65.9543 134.963 63.7537 135.268 61.4298C135.47 59.8922 135.487 58.3433 135.504 56.7943C135.515 55.713 135.527 54.6316 135.602 53.554C135.773 51.0866 136.273 48.5612 137.732 46.5092C138.43 45.529 139.297 44.6836 140.305 44.0796C140.304 44.0511 140.304 44.0226 140.304 43.9939C140.304 42.4323 141.572 41.1664 143.138 41.1664C144.703 41.1664 145.972 42.4323 145.972 43.9939C145.972 45.5555 144.703 46.8214 143.138 46.8214C142.058 46.8214 141.119 46.2186 140.64 45.3318C139.338 46.2173 138.344 47.6051 137.782 49.0371C136.828 51.4693 136.793 54.1205 136.765 56.6938L136.764 56.7518C136.737 59.2689 136.709 61.8538 135.937 64.2749C135.246 66.4453 133.926 68.3336 132.156 69.7688C130.435 71.1637 128.418 72.1507 126.296 72.7726C123.722 73.5254 121.008 73.7344 118.338 73.7772C117.206 73.7951 116.066 73.7804 114.924 73.7627C114.655 75.0449 113.515 76.008 112.149 76.008C110.79 76.008 109.654 75.0531 109.379 73.7789C107.089 73.8801 104.822 74.1633 102.617 74.8699C99.6042 75.8357 96.7141 77.8071 95.1816 80.5819C96.227 80.9982 96.9657 82.0175 96.9657 83.2089C96.9657 84.7705 95.6967 86.0364 94.1313 86.0364C92.5659 86.0364 91.297 84.7705 91.297 83.2089Z'
      fill='url(#paint22_linear_600_800)'
    />
    <path
      d='M127.782 51.8998C126.561 55.5028 124.577 58.8501 121.959 61.6134C122.289 62.0753 122.482 62.6402 122.482 63.2502C122.482 64.8117 121.213 66.0777 119.648 66.0777C118.083 66.0777 116.814 64.8117 116.814 63.2502C116.814 61.6886 118.083 60.4226 119.648 60.4226C120.145 60.4226 120.612 60.5503 121.018 60.7745C123.887 57.7587 125.956 54.01 127.04 49.9989C128.189 45.7438 128.148 41.1689 126.95 37.012C126.78 36.4243 126.592 35.8456 126.384 35.2763C126.163 35.3311 125.933 35.3603 125.695 35.3603C124.13 35.3603 122.861 34.0943 122.861 32.5328C122.861 31.6393 123.276 30.8426 123.925 30.3244C122.93 28.7848 121.749 27.3366 120.372 25.9864C117.144 22.8215 113.063 20.6587 108.654 19.6617C105.06 18.8493 101.304 18.8268 97.7117 19.6111C97.734 19.7539 97.7455 19.9003 97.7455 20.0494C97.7455 21.611 96.4766 22.8769 94.9112 22.8769C93.3458 22.8769 92.0769 21.611 92.0769 20.0494C92.0769 18.4878 93.3458 17.2219 94.9112 17.2219C95.8706 17.2219 96.7187 17.6975 97.2315 18.4252C101.649 17.4206 106.336 17.5976 110.675 18.8887C115.183 20.2282 119.264 22.7838 122.402 26.286C123.377 27.3751 124.263 28.5452 125.049 29.7792C125.256 29.7308 125.473 29.7052 125.695 29.7052C127.261 29.7052 128.53 30.9712 128.53 32.5328C128.53 33.404 128.135 34.1831 127.514 34.7018C127.946 35.8489 128.298 37.026 128.562 38.223C129.564 42.7526 129.272 47.5062 127.782 51.8998Z'
      fill='url(#paint23_linear_600_800)'
    />
    <defs>
      <linearGradient id='paint0_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint1_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint2_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint3_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint4_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint5_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint6_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint7_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint8_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint9_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint10_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint11_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint12_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint13_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint14_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint15_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint16_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint17_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint18_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint19_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint20_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint21_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint22_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient id='paint23_linear_600_800' x1={80} y1={0} x2={80} y2={142} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(UnionIcon);
export { Memo as UnionIcon };
