import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import { Component12Frame16 } from '../Component12Frame16/Component12Frame16';
import { Group12Icon } from './Group12Icon';
import classes from './MenuGroup17.module.css';
import { NounEarthquake2531879Optimized } from './NounEarthquake2531879Optimized';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounEarthquake2531879Optimized?: ReactNode;
  };
}
/* @figmaId 357:1104 */
export const MenuGroup17: FC<Props> = memo(function MenuGroup17(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.group12}>
        <Group12Icon className={classes.icon2} />
      </div>
      <Component12Frame16
        className={classes.component12Frame16}
        swap={{
          nounEarthquake2531879Optimized: props.swap?.nounEarthquake2531879Optimized || (
            <NounEarthquake2531879Optimized className={classes.icon} />
          ),
        }}
      />
    </div>
  );
});
