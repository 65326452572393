import axios from 'axios';
import qs from 'qs';
import type { FC, ReactNode } from 'react';
import React, { memo, useState, ChangeEvent } from 'react';


import resets from '../../../_resets.module.css';
import classes from './SendEmailForm.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    name?: ReactNode;
  };
}

export const SendEmailForm: FC<Props> = memo(function SendEmailForm(props = {}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const formattedInput = input
      .replace(/\D/g, '')
      .slice(0, 10)
      .replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3');
    setPhone(formattedInput);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setEmail(input);
    
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    const inputElement = e.target as HTMLInputElement;
  
    if (!isValidEmail) {
      inputElement.setCustomValidity('Lütfen geçerli bir e-posta adresi giriniz!');
    } else {
      inputElement.setCustomValidity('');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const toMailAddress = "info@yzvb.org";
    const formData = {
      fullname: name,
      email,
      number: phone,
      message,
      toMailAddress
    };

    try {
      // Send the form data to your backend using Axios
      // 'http://ronobt.com.tr/script.php' please, Use this address to test from your localhost
      const response = await axios.post('https://ronobt.com.tr/script.php', qs.stringify(formData), {
      // const response = await axios.post('./script.php', qs.stringify(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      console.log(response);
      console.log(response.data); // Handle the response from the backend

      // Reset the form after successful submission
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');

      window.location.reload();

      if(response.status === 200)
        window.alert("Mesajınız alınmıştır!");
    } catch (error) {
      console.error(error); // Handle any error that occurs during the request
    }

    console.log("Form was submitted");
  };

  return  (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.formGroup}>
          <label>Ad Soyad</label>
          <input type="text" value={name} placeholder="Ad Soyad" required onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={classes.formGroup}>
          <label>E-posta</label>
          <input type="email"  value={email} placeholder="E-posta" required 
          onChange={handleEmailChange}/>
        </div>
        <div className={classes.formGroup}>
          <label>Telefon Numarası</label>
          <input type="tel" value={phone} placeholder="Telefon Numarası" required onChange={handlePhoneChange} />
        </div>
        <div className={classes.formGroup}>
          <label>Mesajınız</label>
          <textarea value={message} placeholder="Mesajınız" required onChange={(e) => setMessage(e.target.value)} />
        </div>
        <div className={classes.buttonContainer}>
          <button>Gönder</button>
        </div>
      </form>
    </div>
  );
});