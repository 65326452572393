import { memo, SVGProps } from 'react';

interface VectorIconProps extends SVGProps<SVGSVGElement> {
  isHovered: boolean;
}

const VectorIcon = ({ isHovered, ...props }: VectorIconProps) => (
  <svg
    preserveAspectRatio='none'
    viewBox='0 0 16 17'
    // fill='none'
    fill={isHovered ? 'white' : '#FF4950'}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    style={{ transition: 'fill 500ms ease' }}
  >
    <path
      d='M8 8.5C6.9 8.5 5.95833 8.10833 5.175 7.325C4.39167 6.54167 4 5.6 4 4.5C4 3.4 4.39167 2.45833 5.175 1.675C5.95833 0.891667 6.9 0.5 8 0.5C9.1 0.5 10.0417 0.891667 10.825 1.675C11.6083 2.45833 12 3.4 12 4.5C12 5.6 11.6083 6.54167 10.825 7.325C10.0417 8.10833 9.1 8.5 8 8.5ZM2.8 16.5C1.2536 16.5 0 15.2464 0 13.7V13.7C0 13.1333 0.146 12.6123 0.438 12.137C0.73 11.6617 1.11733 11.2993 1.6 11.05C2.63333 10.5333 3.68333 10.1457 4.75 9.887C5.81667 9.62833 6.9 9.49933 8 9.5C9.1 9.5 10.1833 9.62933 11.25 9.888C12.3167 10.1467 13.3667 10.534 14.4 11.05C14.8833 11.3 15.271 11.6627 15.563 12.138C15.855 12.6133 16.0007 13.134 16 13.7V13.7C16 15.2464 14.7464 16.5 13.2 16.5H2.8Z'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };
