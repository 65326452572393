import { memo, SVGProps } from 'react';

const Frame35Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 40 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M35 7.7451C33.8958 8.22549 32.7055 8.54118 31.4723 8.69216C32.7342 7.96471 33.7094 6.81176 34.1683 5.42549C32.978 6.11176 31.6587 6.59216 30.2677 6.86667C29.1348 5.68627 27.543 5 25.7361 5C22.3662 5 19.6128 7.63529 19.6128 10.8882C19.6128 11.3549 19.6702 11.8078 19.7706 12.2333C14.6654 11.9863 10.1195 9.63922 7.09369 6.08431C6.5631 6.94902 6.26195 7.96471 6.26195 9.03529C6.26195 11.0804 7.33748 12.8922 9.00096 13.9216C7.98279 13.9216 7.03633 13.6471 6.20459 13.2353V13.2765C6.20459 16.1314 8.32696 18.5196 11.1377 19.0549C10.2353 19.2913 9.2879 19.3242 8.36998 19.151C8.75947 20.3211 9.52228 21.3449 10.5512 22.0785C11.5801 22.8122 12.8233 23.2188 14.1061 23.2412C11.9316 24.8888 9.23613 25.7795 6.46272 25.7667C5.97514 25.7667 5.48757 25.7392 5 25.6843C7.72467 27.3588 10.9656 28.3333 14.4359 28.3333C25.7361 28.3333 31.9455 19.3569 31.9455 11.5745C31.9455 11.3137 31.9455 11.0667 31.9312 10.8059C33.1358 9.98235 34.1683 8.93922 35 7.7451Z'
      fill='#212121'
    />
  </svg>
);

const Memo = memo(Frame35Icon);
export { Memo as Frame35Icon };
