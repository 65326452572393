import { memo } from 'react';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import resets from '../../../_resets.module.css';
import classes from './HeaderSubject_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    misyonumuz?: ReactNode;
  };
  navigateTo?: string; // New prop for specifying the destination page
}

export const HeaderSubject_Property1Default: FC<Props> = memo(function HeaderSubject_Property1Default(props = {}) {
  const navigate = useNavigate();

  function handleClick() {
    if (props.navigateTo) {
      navigate(props.navigateTo);
      window.scrollTo(0, 0);
    }
  }

  return (
    <div
      className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
      onClick={handleClick}
    >
      {props.text?.misyonumuz != null ? (
        props.text?.misyonumuz
      ) : (
        <div className={classes.misyonumuz}>Misyonumuz</div>
      )}
    </div>
  );
});
