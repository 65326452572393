import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import classes from './Iletisim.module.css';
import { NounMail5763984OptimizedIcon } from './NounMail5763984OptimizedIcon';
import { SendEmailForm } from './SendEmailForm/SendEmailForm';

interface Props {
  className?: string;
}
/* @figmaId 167:183 */
export const Iletisim: FC<Props> = memo(function Iletisim(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      
      <div className={classes.nounMail5763984Optimized}>
        <NounMail5763984OptimizedIcon className={classes.icon} />
      </div>
      <div className={classes.frame13}>
        <div className={classes.mainpage}></div>
      </div>
      {/* <Frame41_Property1Default className={classes.frame41} /> */}
      <Header/>
      <div className={classes.bizeUlasN}>Bize Ulaşın.</div>
      <SendEmailForm className={classes.sendEmailForm}/>
      <Footer/>
    </div>
  );
});
