import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import { Component12Frame15 } from '../Component12Frame15/Component12Frame15';
import { Group11Icon } from './Group11Icon';
import classes from './MenuGroup16.module.css';
import { NounPlants1731835OptimizedIcon } from './NounPlants1731835OptimizedIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounPlants1731835Optimized?: ReactNode;
  };
}
/* @figmaId 357:1098 */
export const MenuGroup16: FC<Props> = memo(function MenuGroup16(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.group11}>
        <Group11Icon className={classes.icon2} />
      </div>
      <Component12Frame15
        className={classes.component12Frame15}
        swap={{
          nounPlants1731835Optimized: props.swap?.nounPlants1731835Optimized || (
            <NounPlants1731835OptimizedIcon className={classes.icon} />
          ),
        }}
      />
    </div>
  );
});
