import { memo, SVGProps } from 'react';

const NounMail5763984OptimizedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 238 239' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M141.971 189.559C140.921 189.559 140.067 188.701 140.067 187.646C140.067 186.591 140.921 185.734 141.971 185.734H143.982C145.032 185.734 145.886 186.591 145.886 187.646C145.886 188.701 145.032 189.559 143.982 189.559H141.971ZM90.0055 198.25C88.9549 198.25 88.1009 197.393 88.1009 196.338C88.1009 195.283 88.9549 194.425 90.0055 194.425H182.632C183.682 194.425 184.536 195.283 184.536 196.338C184.536 197.393 183.682 198.25 182.632 198.25H90.0055ZM152.769 189.559C151.719 189.559 150.865 188.701 150.865 187.646C150.865 186.591 151.719 185.734 152.769 185.734H194.399C195.45 185.734 196.304 186.591 196.304 187.646C196.304 188.701 195.45 189.559 194.399 189.559H152.769ZM82.7397 194.432C84.433 194.364 85.8674 193.986 87.2008 193.269L199.649 131.849C201.9 130.618 203.487 128.607 204.252 126.33L133.461 114.466L127.468 130.438C127.134 131.334 126.196 131.825 125.289 131.628L108.677 128.001L80.1209 194.126C80.9736 194.343 81.8528 194.449 82.7387 194.433L82.7397 194.432ZM134.828 110.826L204.755 122.547C204.67 121.156 204.289 119.761 203.588 118.462L164.264 45.8602C163.384 44.2384 162.109 42.9607 160.621 42.0843L134.828 110.828L134.828 110.826ZM76.6945 192.496L104.9 127.177L33.4149 111.57C33.3378 113.297 33.7124 115.066 34.5889 116.685L73.9167 189.293C74.618 190.592 75.5769 191.668 76.6938 192.495L76.6945 192.496ZM34.3033 107.852L124.495 127.547L157.034 40.821C155.001 40.521 152.86 40.8531 150.933 41.9054L38.5254 103.304C36.5956 104.357 35.1546 105.98 34.3019 107.855L34.3033 107.852Z'
      fill='url(#paint0_linear_530_863)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_530_863'
        x1={119.08}
        y1={40.7154}
        x2={119.08}
        y2={198.25}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF9696' />
        <stop offset={1} stopColor='#FF4950' />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(NounMail5763984OptimizedIcon);
export { Memo as NounMail5763984OptimizedIcon };
