import { memo } from 'react';
import type { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import classes from './App.module.css';
import resets from './components/_resets.module.css';
import { Homepage } from './components/Desktop/Homepage/Homepage';
import { Projelerimiz } from './components/Desktop/Projelerimiz/Projelerimiz';
import { SponsorOl } from './components/Desktop/SponsorOl/SponsorOl';
import { Iletisim } from './components/Desktop/Iletisim/Iletisim';
import { Misyonumuz } from './components/Desktop/Misyonumuz/Misyonumuz';
import { GonulluOl } from './components/Desktop/GonulluOl/GonulluOl';
import { HomepageMobil } from './components/Mobile/HomepageMobil/HomepageMobil';
import { SponsorOlMobil } from './components/Mobile/SponsorOlMobil/SponsorOlMobil';
import { MisyonumuzMobil } from './components/Mobile/MisyonumuzMobil/MisyonumuzMobil';
import { GonulluOlMobil } from './components/Mobile/GonulluOlMobil/GonulluOlMobil';
import { IletisimMobil } from './components/Mobile/IletisimMobil/IletisimMobil';
import { Projects } from './components/Mobile/Projects/Projects';

interface Props {
  className?: string;
}
export const App: FC<Props> = memo(function App(props = {}) {

  function deviceType(page: string): JSX.Element | null{
    if(window.matchMedia("(min-width: 300px) and (max-width: 768px)").matches){
      switch(page){
        case("main"): return <HomepageMobil />;
        case("mission"): return <MisyonumuzMobil/>
        case("projects"): return <Projects/>
        case("beSponsor"): return <SponsorOlMobil/>
        case("contact"): return <IletisimMobil />;
        case("beVolunteer"): return <GonulluOlMobil />;
      } 
    }
    else if(window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches){
      switch(page){
        case("main"): return <Homepage />;
        case("mission"): return <Misyonumuz/>
        case("projects"): return <Projelerimiz/>
        case("beSponsor"): return <SponsorOl/>
        case("contact"): return <Iletisim />;
        case("beVolunteer"): return <GonulluOl />;
      }
    }
    else{
      switch(page){
        case("main"): return <Homepage />;
        case("mission"): return <Misyonumuz/>
        case("projects"): return <Projelerimiz/>
        case("beSponsor"): return <SponsorOl/>
        case("contact"): return <Iletisim />;
        case("beVolunteer"): return <GonulluOl />;
      }
    }

    return null;

  }

  let mainPage = deviceType('main');
  let missionPage = deviceType('mission');
  let projectsPage = deviceType('projects');
  let beSponsorPage = deviceType('beSponsor');
  let contactPage = deviceType('contact');
  let beVolunteerPage = deviceType('beVolunteer');
  
  // return (
  //   // <div className={`${resets.clapyResets} ${classes.root}`}>
  //     <Homepage />
  //   // </div>
  // );


  return (
    <Router>
      <Routes>
      <Route path='/' element={mainPage} />
      <Route path='/mission' element={missionPage}/>
      <Route path='/projects' element={projectsPage} />
      <Route path='/beSponsor' element={beSponsorPage} />
      <Route path='/contact' element={contactPage} />
      <Route path='/beVolunteer' element={beVolunteerPage} />
      </Routes>
    </Router>
  );
});
