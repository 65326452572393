import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { Group19Icon } from './Group19Icon';
import classes from './MisyonumuzMobil.module.css';
import { NounMissions5673219OptimizedIc } from './NounMissions5673219OptimizedIc';
import { TuzuguIndir_Property1Default } from './TuzuguIndir_Property1Default/TuzuguIndir_Property1Default';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';

interface Props {
  className?: string;
  hide?: {
    vector?: boolean;
  };
}
/* @figmaId 346:481 */
export const MisyonumuzMobil: FC<Props> = memo(function MisyonumuzMobil(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.misyonumuz}>Misyonumuz</div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon6} />
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon7} />
      </div>
      <div className={classes.nounMissions5673219Optimized}>
        <NounMissions5673219OptimizedIc className={classes.icon8} />
      </div>
      <div className={classes.yapayZekaEkoSisteminiOrtakBirC}>
        <p className={classes.labelWrapper2}>
          <span className={classes.label2}> Yapay zeka eko-sistemini ortak bir çatı altında toplayarak </span>
          <span className={classes.label3}>
            sivil toplum faaliyetlerinin bu alanda etkinleştirilmesi ve geliştirilmesini sağlamak,
          </span>
        </p>
        <div className={classes.textBlock3}>
          <p className={classes.labelWrapper3}></p>
        </div>
        <p className={classes.labelWrapper4}>
          <span className={classes.label4}> Yapay Zeka konusunda çalışmalar yapan kişi ve kuruluşları </span>
          <span className={classes.label5}>dernek eko-sistemine dahil ederek destek vermek,</span>
        </p>
        <div className={classes.textBlock4}>
          <p className={classes.labelWrapper5}></p>
        </div>
        <p className={classes.labelWrapper6}>
          <span className={classes.label6}>
            {' '}
            Analitik ve Yapay zeka konusunda çözümler geliştiren yazılımcıları, bu alanda çalışmalar yapmak isteyen{' '}
          </span>
          <span className={classes.label7}>
            kamu kurumları ve farklı sektörlerdeki kuruluşlar ile bir araya getirmek,
          </span>
        </p>
        <div className={classes.textBlock5}>
          <p className={classes.labelWrapper7}></p>
        </div>
        <p className={classes.labelWrapper8}>
          <span className={classes.label8}> Analitik ve Yapay zeka çözümlerine ilgi duyan</span>
          <span className={classes.label9}> kurum ve kuruluşlara danışmanlık yapmak ve eğitimler düzenlemek,</span>
        </p>
        <div className={classes.textBlock6}>
          <p className={classes.labelWrapper9}></p>
        </div>
        <p className={classes.labelWrapper10}>
          <span className={classes.label10}> Yapay zeka alanında kamusal ve sektörel ihtiyaçlara yönelik </span>
          <span className={classes.label11}>danışma ve çalışma grupları oluşturmak,</span>
        </p>
        <div className={classes.textBlock7}>
          <p className={classes.labelWrapper11}></p>
        </div>
        <p className={classes.labelWrapper12}>
          <span className={classes.label12}> Araştırmalarında yapay zekaya dayalı yöntemleri uygulamak isteyen </span>
          <span className={classes.label13}>akademisyenlere danışmanlık ve destek sağlamak,</span>
        </p>
        <div className={classes.textBlock8}>
          <p className={classes.labelWrapper13}></p>
        </div>
        <p className={classes.labelWrapper14}>
          <span className={classes.label14}>
            {' '}
            Analitik, yapay zeka, veri bilimi, makine öğrenimi, kodlama, robotik, yazılım, veri yönetimi, istatistik,
            matematik gibi alanlarda
          </span>
          <span className={classes.label15}> eğitimler, sınavlar ve sertifikalar sağlamak,</span>
        </p>
        <div className={classes.textBlock9}>
          <p className={classes.labelWrapper15}></p>
        </div>
        <p className={classes.labelWrapper16}>
          <span className={classes.label16}>
            {' '}
            Analitik, yapay zeka, veri bilimi, makine öğrenimi, mühendislik, matematik, istatistik, beşeri bilimler,
            eğitim, sanat ve spor başta olmak üzere tüm bilimsel alanlarda{' '}
          </span>
          <span className={classes.label17}>
            eğitim faaliyetleri, kültürel değişim programları ve sosyal faaliyetler düzenlemek, projeler geliştirmek,
          </span>
        </p>
        <div className={classes.textBlock10}>
          <p className={classes.labelWrapper17}></p>
        </div>
        <p className={classes.labelWrapper18}>
          <span className={classes.label18}> </span>
          <span className={classes.label19}>
            Doğanın ve hayvanların korunması, çevresel olumsuz etkilerin azaltılması, afet durumlarında destek olacak
            teknolojilerin geliştirilmesi, dezavantajlı grupların desteklenmesi, cinsiyet eşitsizliğinin giderilmesi
          </span>
          <span className={classes.label20}>
            {' '}
            gibi alanlarda sosyal sorumluluk projeleri geliştirmek ve bu alanlarda yapay zeka ve veri biliminin
            kullanımına yönelik faaliyetler göstermek amacı ile kurulmuştur.
          </span>
        </p>
      </div>
      <div className={classes.group19}>
        <Group19Icon className={classes.icon9} />
      </div>
      <TuzuguIndir_Property1Default className={classes.tuzuguIndir} />
      <div className={classes.detaylBilgiIcin}>Detaylı bilgi için.</div>
      <Header/>
      <Footer/>
    </div>
  );
});
