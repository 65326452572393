import { memo, SVGProps } from 'react';

const MingcuteNavigationFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_608_825)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.6207 2.46858C19.497 2.15325 20.344 3.00025 20.0287 3.87567L14.492 19.2546C14.1482 20.2107 12.8035 20.229 12.4332 19.2821L9.83991 12.6564L3.21424 10.0641C2.26824 9.69375 2.28658 8.349 3.24174 8.00433L18.6207 2.46767V2.46858Z'
        fill='#FF4950'
      />
    </g>
    <defs>
      <clipPath id='clip0_608_825'>
        <rect width={22} height={22} fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(MingcuteNavigationFillIcon);
export { Memo as MingcuteNavigationFillIcon };
