import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { NounAttendant550573OptimizedIc } from './NounAttendant550573OptimizedIc';
import { NounDog4432245OptimizedIcon } from './NounDog4432245OptimizedIcon';
import { NounEarthquake2531879Optimized } from './NounEarthquake2531879Optimized';
import { NounEquality4831999OptimizedIc } from './NounEquality4831999OptimizedIc';
import { NounPlants1731835OptimizedIcon } from './NounPlants1731835OptimizedIcon';
import classes from './Projects.module.css';
import { ProjeKonular_Property1Default } from './ProjeKonular_Property1Default/ProjeKonular_Property1Default';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';
import { Vector5Icon } from './Vector5Icon';

interface Props {
  className?: string;
  hide?: {
    vector?: boolean;
  };
}
/* @figmaId 346:361 */
export const Projects: FC<Props> = memo(function Projects(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.projeAlanlarMZ}>Proje Alanlarımız</div>
      <div className={classes.yapayZekaVeVeriBilimiIleSosyal}>
        <p className={classes.labelWrapper}>
          <span className={classes.label}>Yapay Zeka ve Veri Bilimi ile </span>
          <span className={classes.label2}>sosyal sorumluluk</span>
          <span className={classes.label3}> çerçevesinde odaklandığımız ana proje konularımız;</span>
        </p>
      </div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon11} />
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon12} />
      </div>
      <div className={classes.cyborgBro1}></div>
      <div className={classes.vector5}>
        <Vector5Icon className={classes.icon13} />
      </div>
      <ProjeKonular_Property1Default
        className={classes.projeKonular}
        swap={{
          nounEarthquake2531879Optimized: <NounEarthquake2531879Optimized className={classes.icon2} />,
          nounPlants1731835Optimized: <NounPlants1731835OptimizedIcon className={classes.icon3} />,
          nounEquality4831999Optimized: <NounEquality4831999OptimizedIc className={classes.icon4} />,
          nounAttendant550573Optimized: <NounAttendant550573OptimizedIc className={classes.icon5} />,
          nounDog4432245Optimized: <NounDog4432245OptimizedIcon className={classes.icon6} />,
        }}
      />
      <Header/>
      <Footer/>
    </div>
  );
});
