import { memo, SVGProps } from 'react';

const UnionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 585 518' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M267.238 12.715C266.151 17.2537 262.05 20.6289 257.157 20.6289C252.692 20.6289 248.887 17.8189 247.429 13.879C241.825 14.3904 236.237 15.057 230.672 15.8804C208.027 19.2236 184.255 24.5336 164.662 36.9045C165.245 38.1938 165.569 39.6239 165.569 41.1292C165.569 46.8257 160.929 51.4436 155.206 51.4436C149.482 51.4436 144.843 46.8257 144.843 41.1292C144.843 35.4327 149.482 30.8148 155.206 30.8148C157.756 30.8148 160.091 31.7316 161.896 33.2522C171.574 27.0827 182.029 22.7164 193.64 19.1869C206.966 15.1364 220.208 12.6566 234.548 10.7462C238.635 10.2017 242.733 9.73926 246.838 9.3587C247.322 4.11023 251.757 0 257.157 0C262.131 0 266.286 3.48779 267.289 8.14026C270.801 8.04663 274.314 8.01208 277.827 8.03662C292.435 8.13763 307.016 9.27655 321.467 11.3799C332.441 12.9774 343.325 15.1628 354.063 17.9198C355.731 14.4483 359.293 12.0504 363.417 12.0504C369.141 12.0504 373.78 16.6683 373.78 22.3648C373.78 28.0613 369.141 32.6793 363.417 32.6793C357.704 32.6793 353.071 28.0786 353.054 22.3966C340.776 19.2261 328.299 16.8002 315.718 15.1824C299.663 13.1215 283.431 12.2849 267.238 12.715Z'
      fill='url(#paint0_linear_502_813)'
    />
    <path
      d='M66.7646 108.049C66.7646 105.996 66.1617 104.082 65.1226 102.475C74.9196 92.8696 85.9098 84.4777 97.826 77.6109C104.406 73.8202 111.242 70.5103 118.296 67.7253C120.173 70.3613 123.262 72.0817 126.756 72.0817C132.479 72.0817 137.119 67.4637 137.119 61.7673C137.119 56.0707 132.479 51.4528 126.756 51.4528C121.032 51.4528 116.393 56.0707 116.393 61.7673C116.393 62.3576 116.443 62.9363 116.538 63.4996C100.692 69.7859 85.9222 78.6167 72.7535 89.4409C68.9722 92.5501 65.3253 95.8281 61.8274 99.2596C60.2493 98.2925 58.3909 97.7346 56.4016 97.7346C50.6782 97.7346 46.0385 102.353 46.0385 108.049C46.0385 110.422 46.8436 112.608 48.1968 114.351C42.5234 121.451 37.4303 129.008 33.0085 136.926C28.5214 144.962 24.7197 153.396 21.6936 162.09C21.5492 162.076 21.4039 162.066 21.2578 162.059C21.0847 162.05 20.9105 162.046 20.7354 162.046C15.012 162.046 10.3723 166.664 10.3723 172.361C10.3723 178.057 15.012 182.675 20.7354 182.675C26.4586 182.675 31.0984 178.057 31.0984 172.361C31.0984 168.606 29.083 165.32 26.0703 163.516C31.8969 146.803 40.6627 131.103 51.7452 117.266C53.1453 117.968 54.7271 118.364 56.4016 118.364C62.1249 118.364 66.7646 113.746 66.7646 108.049Z'
      fill='url(#paint1_linear_502_813)'
    />
    <path
      d='M352.809 353.46C347.905 354.276 344.168 358.52 344.168 363.632C344.168 369.329 348.807 373.947 354.531 373.947C360.254 373.947 364.894 369.329 364.894 363.632C364.894 359.023 361.856 355.12 357.664 353.798C362.249 342.73 371.464 333.557 381.735 327.463C399.24 317.075 420.335 314.999 440.222 313.263L442.42 313.072C451.004 312.327 459.839 311.561 468.601 310.201C470.094 314.071 473.864 316.818 478.278 316.818C484.001 316.818 488.641 312.2 488.641 306.503C488.641 306.243 488.631 305.984 488.612 305.728C492.599 304.494 496.514 303.031 500.324 301.277C517.082 293.562 530.795 279.702 538.232 262.867C545.971 245.354 546.739 225.101 540.362 207.053C542.631 205.161 544.074 202.32 544.074 199.143C544.074 193.447 539.434 188.829 533.711 188.829C527.987 188.829 523.348 193.447 523.348 199.143C523.348 204.84 527.987 209.458 533.711 209.458C534.574 209.458 535.412 209.353 536.214 209.155C541.478 224.61 541.347 241.799 535.621 257.145C529.41 273.806 516.731 287.831 500.868 295.913C496.481 298.147 491.934 299.936 487.28 301.389C485.494 298.283 482.132 296.189 478.278 296.189C472.823 296.189 468.352 300.384 467.945 305.71C459.729 306.971 451.403 307.691 443.214 308.399L442.298 308.478C422.458 310.196 400.477 312.134 382.095 321.97C369.411 328.759 357.901 339.821 352.809 353.46Z'
      fill='url(#paint2_linear_502_813)'
    />
    <path
      d='M389.311 359.646C392.48 359.646 395.317 361.062 397.218 363.293C412.412 358.446 428.526 356.84 444.393 355.936C448.581 355.697 452.78 355.51 456.981 355.323H456.984C471.713 354.667 486.477 354.01 500.951 351.123C506.436 350.03 511.92 348.614 517.259 346.786C517.234 346.652 517.211 346.518 517.191 346.383C517.165 346.201 517.143 346.018 517.126 345.833C517.097 345.519 517.082 345.2 517.082 344.877C517.082 339.181 521.722 334.563 527.445 334.563C530.681 334.563 533.571 336.039 535.471 338.352C538.706 336.37 541.794 334.151 544.692 331.67C556.771 321.328 564.735 307.385 567.245 291.11C569.184 278.542 567.165 265.524 561.904 253.982C561.056 254.206 560.164 254.325 559.245 254.325C553.521 254.325 548.882 249.707 548.882 244.011C548.882 238.314 553.521 233.696 559.245 233.696C564.968 233.696 569.608 238.314 569.608 244.011C569.608 247.135 568.212 249.935 566.007 251.827C572.421 265.781 574.234 281.72 570.872 296.731C567.264 312.859 557.897 327.417 544.775 337.502C542.446 339.292 540.022 340.941 537.522 342.459C537.709 343.235 537.808 344.044 537.808 344.877C537.808 350.574 533.168 355.192 527.445 355.192C524.03 355.192 521.001 353.548 519.113 351.012C512.495 353.311 505.648 354.993 498.792 356.193C484.904 358.626 470.839 359.275 456.776 359.923H456.774C451.253 360.177 445.732 360.432 440.222 360.794C426.49 361.701 412.547 363.349 399.361 367.434C399.565 368.243 399.674 369.089 399.674 369.961C399.674 375.657 395.034 380.275 389.311 380.275C383.588 380.275 378.948 375.657 378.948 369.961C378.948 364.264 383.588 359.646 389.311 359.646Z'
      fill='url(#paint3_linear_502_813)'
    />
    <path
      d='M336.398 470.066C332.594 471.594 329.91 475.303 329.91 479.635C329.91 485.332 334.55 489.95 340.273 489.95C345.997 489.95 350.636 485.332 350.636 479.635C350.636 474.156 346.343 469.674 340.922 469.341C339.5 458.673 338.661 447.832 340.55 437.23C342.433 426.658 347.148 416.224 355.444 409.134C355.71 408.906 355.978 408.684 356.25 408.468C358.036 409.923 360.32 410.796 362.808 410.796C368.279 410.796 372.759 406.577 373.144 401.23C378.259 400.303 383.568 400.125 388.72 400.279C392.55 400.395 396.394 400.684 400.241 400.974C410.153 401.72 420.09 402.468 429.868 400.252C437.15 398.597 444.381 394.973 449.591 389.564C450.954 390.221 452.484 390.59 454.101 390.59C459.824 390.59 464.464 385.972 464.464 380.275C464.464 374.579 459.824 369.961 454.101 369.961C448.377 369.961 443.738 374.579 443.738 380.275C443.738 382.701 444.579 384.932 445.988 386.693C441.526 391.186 435.386 394.182 429.296 395.669C419.781 397.985 409.967 397.199 400.225 396.418C396.372 396.109 392.529 395.802 388.72 395.687C383.292 395.526 377.781 395.753 372.46 396.719C370.95 392.883 367.198 390.167 362.808 390.167C357.085 390.167 352.445 394.785 352.445 400.482C352.445 402.051 352.797 403.538 353.427 404.87C344.578 411.824 339.094 422.484 336.647 433.289C333.905 445.403 334.779 457.826 336.398 470.066Z'
      fill='url(#paint4_linear_502_813)'
    />
    <path
      d='M385.103 444.421C380.154 449.228 376.571 455.758 375.713 462.57C380.207 463.698 383.534 467.749 383.534 472.572C383.534 478.269 378.895 482.887 373.171 482.887C367.448 482.887 362.808 478.269 362.808 472.572C362.808 467.603 366.339 463.454 371.04 462.476C371.998 454.031 376.562 445.871 382.833 440.233C392.227 431.792 404.823 428.026 417.106 426.272C422.484 425.504 427.908 425.067 433.332 424.63C441.971 423.935 450.61 423.239 459.056 421.202C459.88 421.004 460.7 420.794 461.518 420.573C461.508 420.507 461.498 420.441 461.489 420.375C461.479 420.303 461.47 420.232 461.461 420.161L461.452 420.075L461.44 419.96C461.437 419.927 461.434 419.895 461.431 419.862L461.421 419.748C461.401 419.485 461.391 419.22 461.391 418.952C461.391 413.255 466.031 408.638 471.754 408.638C475.358 408.638 478.533 410.469 480.389 413.248C491.255 407.655 501.061 400.018 509.066 390.808C507.599 389.028 506.719 386.752 506.719 384.271C506.719 378.574 511.359 373.956 517.082 373.956C522.805 373.956 527.445 378.574 527.445 384.271C527.445 389.967 522.805 394.585 517.082 394.585C515.511 394.585 514.022 394.237 512.688 393.615C506.415 400.863 499.107 407.194 491.022 412.412C488.12 414.286 485.115 416.014 482.027 417.584C482.06 417.837 482.085 418.092 482.1 418.35L482.107 418.493C482.11 418.562 482.112 418.631 482.114 418.701C482.116 418.784 482.117 418.868 482.117 418.952C482.117 424.649 477.477 429.266 471.754 429.266C468.24 429.266 465.135 427.526 463.261 424.864C462.846 424.978 462.431 425.089 462.015 425.198C461.093 425.438 460.168 425.665 459.241 425.877C451.119 427.741 442.842 428.402 434.561 429.063L434.555 429.064C428.884 429.517 423.211 429.97 417.586 430.809C405.977 432.545 393.722 436.045 385.103 444.421Z'
      fill='url(#paint5_linear_502_813)'
    />
    <path
      d='M294.381 425.577C295.891 423.785 296.8 421.474 296.8 418.952C296.8 413.255 292.161 408.638 286.437 408.638C280.714 408.638 276.074 413.255 276.074 418.952C276.074 424.649 280.714 429.266 286.437 429.266C287.959 429.266 289.404 428.94 290.706 428.354C295.829 434.697 300.105 441.702 303.472 449.161C308.677 460.678 311.593 473.28 311.962 485.899C312.076 489.733 311.957 493.566 311.596 497.377C311.482 497.373 311.367 497.371 311.251 497.371C305.528 497.371 300.888 501.989 300.888 507.686C300.888 513.382 305.528 518 311.251 518C316.975 518 321.614 513.382 321.614 507.686C321.614 503.732 319.38 500.298 316.099 498.567C317.121 488.572 316.57 478.452 314.546 468.577C311.906 455.682 306.72 443.218 299.338 432.288C297.781 429.983 296.127 427.743 294.381 425.577Z'
      fill='url(#paint6_linear_502_813)'
    />
    <path
      d='M173.394 351.169C173.394 355.554 170.645 359.3 166.768 360.792C167.89 365.395 169.649 369.843 171.991 373.984C179.272 386.851 191.887 395.577 206.015 399.581C218.08 402.998 230.73 402.894 242.974 400.594L242.973 400.482C242.973 394.785 247.613 390.167 253.336 390.167C259.06 390.167 263.699 394.785 263.699 400.482C263.699 406.178 259.06 410.796 253.336 410.796C249.271 410.796 245.753 408.466 244.055 405.075C238.059 406.218 231.967 406.852 225.865 406.847C209.752 406.828 193.576 402.052 181.026 391.784C171.56 384.049 164.998 373.226 162.205 361.451C156.868 361.032 152.668 356.588 152.668 351.169C152.668 345.472 157.308 340.854 163.031 340.854C168.755 340.854 173.394 345.472 173.394 351.169Z'
      fill='url(#paint7_linear_502_813)'
    />
    <path
      d='M8.16602 226.989C8.53845 236.79 9.98975 246.557 12.4578 256.052C15.8538 269.149 21.2059 281.787 28.2747 293.342C35.2417 304.731 43.9253 315.073 53.9838 323.89C63.8301 332.524 74.9037 339.78 86.7894 345.318C97.0348 350.1 107.849 353.674 118.955 355.823C119.323 361.186 123.811 365.423 129.294 365.423C135.017 365.423 139.657 360.805 139.657 355.109C139.657 349.412 135.017 344.794 129.294 344.794C124.918 344.794 121.175 347.494 119.656 351.311C110.497 349.527 101.507 346.756 92.9629 343.086C81.3448 338.099 70.3542 331.513 60.5911 323.495C50.6433 315.339 41.9136 305.759 34.8173 295.05C27.5455 284.065 21.9349 272.042 18.1792 259.432C15.0311 248.862 13.1927 237.944 12.7671 226.942C17.3312 225.863 20.7261 221.779 20.7261 216.906C20.7261 211.21 16.0864 206.592 10.363 206.592C4.63965 206.592 0 211.21 0 216.906C0 221.853 3.49792 225.986 8.16602 226.989Z'
      fill='url(#paint8_linear_502_813)'
    />
    <path
      d='M445.325 48.3392C434.349 41.9265 422.722 36.6252 410.54 33.0329C408.821 36.3624 405.336 38.6401 401.317 38.6401C395.593 38.6401 390.954 34.0222 390.954 28.3257C390.954 22.6292 395.593 18.0112 401.317 18.0112C407.04 18.0112 411.68 22.6292 411.68 28.3257C411.68 28.4035 411.679 28.4811 411.677 28.5586C426.183 32.8011 439.952 39.4515 452.735 47.4391C467.084 56.4034 480.336 67.0485 492.729 78.511C496.802 82.2736 500.784 86.1309 504.688 90.0662C505.95 89.522 507.342 89.2204 508.804 89.2204C514.528 89.2204 519.167 93.8383 519.167 99.5349C519.167 101.196 518.773 102.765 518.073 104.154C521.07 107.439 524.026 110.76 526.947 114.111C538.426 127.282 549.306 141.05 558.202 156.104C565.889 169.116 572.11 183.154 575.458 197.908C580.797 198.324 585 202.769 585 208.19C585 213.887 580.36 218.505 574.637 218.505C568.914 218.505 564.274 213.887 564.274 208.19C564.274 203.811 567.015 200.07 570.883 198.573C567.107 182.253 559.761 166.974 550.801 152.861C541.721 138.561 530.933 125.472 519.684 112.825C518.167 111.117 516.638 109.418 515.1 107.729C513.354 109.059 511.172 109.849 508.804 109.849C503.081 109.849 498.441 105.231 498.441 99.5349C498.441 96.9723 499.38 94.628 500.934 92.8242C495.815 87.6793 490.56 82.6689 485.144 77.8314C472.815 66.8188 459.628 56.6973 445.325 48.3392Z'
      fill='url(#paint9_linear_502_813)'
    />
    <path
      d='M46.0293 182.399C46.1255 182.399 46.2214 182.401 46.317 182.403C46.3729 182.405 46.4288 182.407 46.4845 182.409L46.5138 182.41L46.5933 182.414L46.6588 182.418C48.947 174.45 51.9299 166.684 55.5433 159.208C65.0574 139.525 79.1024 122.019 96.4049 108.582C111.211 97.08 128.168 88.417 146.203 83.2811C146.558 77.9042 151.052 73.6523 156.544 73.6523C162.267 73.6523 166.907 78.2702 166.907 83.9667C166.907 89.6633 162.267 94.2812 156.544 94.2812C152.199 94.2812 148.479 91.6204 146.939 87.8467C138.672 90.2303 130.629 93.3618 122.935 97.2203C103.907 106.772 87.0293 120.559 74.2024 137.523C63.7301 151.378 55.8773 167.08 51.0959 183.714C54.2567 185.481 56.3923 188.849 56.3923 192.714C56.3923 198.41 51.7527 203.028 46.0293 203.028C40.3059 203.028 35.6663 198.41 35.6663 192.714C35.6663 187.017 40.3059 182.399 46.0293 182.399Z'
      fill='url(#paint10_linear_502_813)'
    />
    <path
      d='M193.024 57.0453C191.184 55.3796 188.739 54.3644 186.055 54.3644C180.332 54.3644 175.692 58.9823 175.692 64.6788C175.692 70.3753 180.332 74.9933 186.055 74.9933C191.778 74.9933 196.418 70.3753 196.418 64.6788C196.418 63.3228 196.155 62.028 195.677 60.8419C208.767 53.9581 223.128 49.4473 237.843 47.7238C255.699 45.6389 273.85 47.6136 290.857 53.3908C296.528 55.3175 302.038 57.6708 307.345 60.4146C307.071 61.3414 306.923 62.3225 306.923 63.3378C306.923 69.0344 311.563 73.6523 317.286 73.6523C323.01 73.6523 327.649 69.0344 327.649 63.3378C327.649 57.6414 323.01 53.0234 317.286 53.0234C314.242 53.0234 311.504 54.3303 309.608 56.4113C296.829 49.7992 282.9 45.3928 268.627 43.4621C250.107 40.9639 231.134 42.663 213.361 48.431C206.351 50.7051 199.532 53.5892 193.024 57.0453Z'
      fill='url(#paint11_linear_502_813)'
    />
    <path
      d='M63.3328 225.89L63.3346 225.889C65.7482 223.83 68.1622 221.77 70.4742 219.598C75.5127 214.858 79.8684 209.504 82.5814 203.111C86.1155 194.79 86.9821 185.885 87.8484 176.982L87.8517 176.947C88.0011 175.412 88.1505 173.878 88.3137 172.346C93.7831 172.064 98.1306 167.56 98.1306 162.046C98.1306 158.004 95.7941 154.504 92.3918 152.813C93.42 150.155 94.7056 147.601 96.3311 145.211C100.022 139.782 104.784 135.897 110.376 133.105C122.253 127.181 136.805 128.687 147.547 136.338C151.256 138.98 154.259 142.386 157.259 145.79L157.26 145.791C159.037 147.807 160.814 149.822 162.736 151.677C166.55 155.358 171.038 158.439 176.011 159.718C177.151 164.182 181.216 167.483 186.055 167.483C191.778 167.483 196.418 162.866 196.418 157.169C196.418 151.472 191.778 146.855 186.055 146.855C181.13 146.855 177.008 150.274 175.953 154.857C172.88 153.835 170.001 152 167.617 149.913C165.025 147.64 162.74 145.062 160.455 142.483L160.454 142.482L160.453 142.481C157.826 139.516 155.199 136.551 152.105 134.051C141.253 125.28 125.971 122.35 112.748 127.117C106.556 129.348 100.89 133.022 96.3958 137.817C92.6088 141.864 89.8 146.602 87.8632 151.732L87.7676 151.732C82.0442 151.732 77.4045 156.35 77.4045 162.046C77.4045 166.348 80.0509 170.035 83.812 171.583C83.6652 172.995 83.5293 174.408 83.3934 175.822C82.7574 182.441 82.1215 189.06 80.3574 195.488C78.5948 201.917 75.4851 207.612 70.9725 212.544C67.7122 216.107 63.8929 219.359 60.0857 222.601L60.0787 222.607L59.4235 223.165C57.9678 222.389 56.3043 221.949 54.5375 221.949C48.8141 221.949 44.1744 226.567 44.1744 232.263C44.1744 237.96 48.8141 242.578 54.5375 242.578C60.2609 242.578 64.9005 237.96 64.9005 232.263C64.9005 230.008 64.1736 227.923 62.9402 226.225L63.3328 225.89Z'
      fill='url(#paint12_linear_502_813)'
    />
    <path
      d='M112.754 268.885C103.718 263.477 96.6163 254.638 93.8185 244.55C88.5684 244.046 84.4639 239.642 84.4639 234.284C84.4639 228.587 89.1036 223.969 94.8269 223.969C100.55 223.969 105.19 228.587 105.19 234.284C105.19 238.708 102.391 242.482 98.4601 243.947C99.5883 247.726 101.399 251.306 103.732 254.555C106.62 258.58 110.237 261.85 114.312 264.405C116.15 261.526 119.383 259.615 123.065 259.615C128.788 259.615 133.428 264.233 133.428 269.93C133.428 270.155 133.421 270.378 133.406 270.6C133.401 270.673 133.396 270.747 133.39 270.82C133.383 270.897 133.375 270.974 133.367 271.051C135.432 271.323 137.511 271.475 139.583 271.51C151.562 271.711 163.106 268.174 173.771 262.947C173.413 261.902 173.219 260.781 173.219 259.615C173.219 253.919 177.859 249.301 183.582 249.301C189.305 249.301 193.945 253.919 193.945 259.615C193.945 265.312 189.305 269.93 183.582 269.93C180.701 269.93 178.095 268.76 176.217 266.872C170.842 269.536 165.262 271.786 159.488 273.402C150.563 275.9 141 276.789 131.806 275.472C129.966 278.341 126.739 280.244 123.065 280.244C117.341 280.244 112.702 275.626 112.702 269.93C112.702 269.577 112.719 269.229 112.754 268.885Z'
      fill='url(#paint13_linear_502_813)'
    />
    <path
      d='M123.96 172.361C122.857 172.361 121.795 172.189 120.798 171.872C117.678 176.615 115.147 181.82 114.787 187.534C114.4 193.697 116.808 199.373 121.8 203.093C132.038 210.726 144.971 208.553 156.556 205.452C156.554 205.376 156.553 205.3 156.553 205.223C156.553 199.527 161.193 194.909 166.916 194.909C170.916 194.909 174.387 197.165 176.115 200.469C186.339 198.441 196.752 197.681 207.076 200.062C219.303 202.881 231.466 210.532 236.634 222.316C240.08 230.184 240.4 239.6 237.003 247.447C238.649 249.273 239.651 251.687 239.651 254.334C239.651 260.031 235.011 264.649 229.288 264.649C223.565 264.649 218.925 260.031 218.925 254.334C218.925 248.638 223.565 244.02 229.288 244.02C230.646 244.02 231.943 244.28 233.132 244.753C236.551 235.984 234.6 225.46 228.707 218.091C220.402 207.703 206.864 203.267 193.908 203.157C188.31 203.105 182.753 203.757 177.272 204.828C177.277 204.96 177.279 205.091 177.279 205.223C177.279 210.92 172.639 215.538 166.916 215.538C162.887 215.538 159.396 213.25 157.681 209.908C154.415 210.793 151.13 211.592 147.786 212.14C140.995 213.251 134.037 213.352 127.466 211.111C121.717 209.145 116.384 205.628 113.172 200.392C109.38 194.193 109.426 186.45 111.687 179.717C112.913 176.066 114.725 172.712 116.819 169.521C114.834 167.642 113.597 164.988 113.597 162.046C113.597 156.35 118.236 151.732 123.96 151.732C129.683 151.732 134.323 156.35 134.323 162.046C134.323 167.743 129.683 172.361 123.96 172.361Z'
      fill='url(#paint14_linear_502_813)'
    />
    <path
      d='M194.259 319.05C196.381 309.592 201.978 300.912 209.64 294.916C209.306 293.903 209.125 292.822 209.125 291.698C209.125 286.001 213.765 281.383 219.488 281.383C225.211 281.383 229.851 286.001 229.851 291.698C229.851 297.394 225.211 302.012 219.488 302.012C216.569 302.012 213.932 300.811 212.048 298.878C203.864 305.483 198.406 315.541 197.941 326.048C197.387 338.586 203.542 351.398 213.988 358.535C220.435 362.942 227.77 365.173 235.36 366.417C236.813 362.466 240.623 359.646 245.096 359.646C250.217 359.646 254.47 363.343 255.309 368.202L256.354 368.261C264.438 368.73 272.604 369.318 280.467 371.366C286.963 373.056 293.174 375.848 298.71 379.641C306.006 384.649 312.15 391.188 316.621 398.74C317.549 398.469 318.531 398.323 319.547 398.323C325.271 398.323 329.91 402.941 329.91 408.638C329.91 414.334 325.271 418.952 319.547 418.952C313.824 418.952 309.184 414.334 309.184 408.638C309.184 405.593 310.51 402.856 312.618 400.968C307.26 391.974 299.327 384.55 289.944 379.797C279.093 374.301 267.033 373.477 255.062 372.796C253.825 377.114 249.831 380.275 245.096 380.275C239.698 380.275 235.264 376.167 234.777 370.921C223.511 369.026 212.589 365.097 204.659 356.478C195.486 346.503 191.398 331.798 194.259 319.05Z'
      fill='url(#paint15_linear_502_813)'
    />
    <path
      d='M279.27 327.856C273.636 321.528 269.741 313.687 268.221 305.319C265.822 292.12 269.725 278.049 278.326 267.771C281.832 263.578 285.879 259.902 289.921 256.232C290.259 255.925 290.598 255.617 290.936 255.31L291.709 254.605C292.985 255.163 294.395 255.473 295.877 255.473C301.601 255.473 306.24 250.855 306.24 245.159C306.24 243.41 305.803 241.764 305.032 240.321C305.819 239.269 306.582 238.195 307.32 237.094C315.026 225.595 319.667 212.112 320.692 198.335C321.674 185.099 319.208 171.634 313.766 159.521C315.53 157.672 316.613 155.172 316.613 152.42C316.613 146.724 311.973 142.106 306.25 142.106C300.526 142.106 295.887 146.724 295.887 152.42C295.887 158.117 300.526 162.735 306.25 162.735C307.519 162.735 308.735 162.508 309.858 162.092C314.342 172.339 316.554 183.528 316.253 194.734C315.884 208.456 311.574 221.949 304.164 233.503C303.447 234.621 302.699 235.713 301.923 236.78C300.221 235.562 298.134 234.844 295.877 234.844C290.154 234.844 285.514 239.462 285.514 245.159C285.514 247.689 286.43 250.007 287.949 251.801L286.885 252.768L286.874 252.779L286.733 252.907C282.818 256.462 278.876 260.042 275.437 264.07C266.228 274.862 261.549 289.163 263.293 303.298C264.552 313.518 269.004 323.275 275.863 330.952C274.699 332.62 274.016 334.646 274.016 336.831C274.016 342.528 278.656 347.146 284.379 347.146C290.103 347.146 294.742 342.528 294.742 336.831C294.742 331.135 290.103 326.517 284.379 326.517C282.521 326.517 280.778 327.004 279.27 327.856Z'
      fill='url(#paint16_linear_502_813)'
    />
    <path
      d='M270.145 205.38L270.048 205.38C264.325 205.38 259.685 209.997 259.685 215.694C259.685 221.391 264.325 226.008 270.048 226.008C275.772 226.008 280.411 221.391 280.411 215.694C280.411 211.642 278.064 208.136 274.649 206.449C275.413 200.015 273.738 193.2 270.583 187.598C265.157 177.954 255.57 171.451 246.378 165.72C245.429 165.128 244.472 164.537 243.51 163.944C243.007 163.634 242.502 163.323 241.998 163.011C242.612 161.693 242.955 160.224 242.955 158.675C242.955 152.979 238.315 148.361 232.592 148.361C229.912 148.361 227.47 149.373 225.631 151.034C223.909 149.354 222.314 147.548 220.891 145.578C214.468 136.687 211.903 126.115 213.573 115.746C214.809 108.089 218.281 100.86 223.424 95.0807C225.092 96.2218 227.112 96.8896 229.288 96.8896C235.011 96.8896 239.651 92.2717 239.651 86.5752C239.651 80.8787 235.011 76.2607 229.288 76.2607C223.565 76.2607 218.925 80.8787 218.925 86.5752C218.925 88.4481 219.427 90.2043 220.303 91.7183C215.711 96.7604 212.252 102.805 210.306 109.381C207.326 119.456 208.313 131.25 212.936 141.114C215.404 146.384 218.917 150.83 223.016 154.726C222.509 155.942 222.229 157.276 222.229 158.675C222.229 164.372 226.868 168.99 232.592 168.99C235.088 168.99 237.378 168.111 239.167 166.648C239.672 166.963 240.179 167.277 240.686 167.591L241.487 168.087L241.52 168.107C249.118 172.807 256.78 177.546 262.657 184.347C267.551 190.008 270.878 197.914 270.145 205.38Z'
      fill='url(#paint17_linear_502_813)'
    />
    <path
      d='M282.258 109.283C287.275 103.975 293.69 99.9994 300.676 97.6611C311.076 94.1802 322.989 94.2536 333.362 98.6439C344.048 103.163 352.851 111.512 358.591 121.486C364.7 132.113 366.924 144.329 365.512 156.453C364.393 166.098 360.944 175.562 355.793 183.879C354.418 183.208 352.871 182.831 351.236 182.831C345.513 182.831 340.873 187.449 340.873 193.146C340.873 198.842 345.513 203.46 351.236 203.46C356.96 203.46 361.599 198.842 361.599 193.146C361.599 190.736 360.77 188.52 359.379 186.764C364.165 179.174 367.599 170.739 369.295 161.936C371.898 148.434 370.431 135.144 364.598 122.993C359.237 111.842 350.295 102.171 339.277 96.3845C328.249 90.5889 315.552 89.101 303.444 92.0768C294.064 94.3868 285.286 99.3003 278.699 106.326C277.29 105.613 275.696 105.211 274.007 105.211C268.284 105.211 263.644 109.829 263.644 115.525C263.644 121.222 268.284 125.84 274.007 125.84C279.73 125.84 284.37 121.222 284.37 115.525C284.37 113.179 283.583 111.016 282.258 109.283Z'
      fill='url(#paint18_linear_502_813)'
    />
    <path
      d='M65.0303 277.742C69.5546 276.635 72.9104 272.57 72.9104 267.726C72.9104 262.029 68.2708 257.411 62.5474 257.411C56.8241 257.411 52.1843 262.029 52.1843 267.726C52.1843 272.673 55.6841 276.807 60.3539 277.808C59.2904 291.33 65.2603 305.271 75.3651 314.365C76.13 315.054 76.9121 315.716 77.71 316.352C77.5105 317.151 77.4045 317.987 77.4045 318.848C77.4045 324.544 82.0442 329.162 87.7676 329.162C90.8354 329.162 93.592 327.835 95.4895 325.726C105.602 328.933 116.612 329.491 127.143 327.784C134.97 326.515 142.564 324.037 149.779 320.753C151.68 323.024 154.542 324.469 157.744 324.469C163.467 324.469 168.107 319.851 168.107 314.154C168.107 308.458 163.467 303.84 157.744 303.84C152.02 303.84 147.38 308.458 147.38 314.154C147.38 315.013 147.486 315.847 147.684 316.645C134.021 322.81 118.819 326.027 103.944 323.174C101.855 322.772 99.7841 322.255 97.7507 321.624C97.9983 320.741 98.1306 319.81 98.1306 318.848C98.1306 313.151 93.4908 308.533 87.7676 308.533C84.6278 308.533 81.8142 309.923 79.9138 312.118C74.3058 307.465 69.8408 301.522 67.2721 294.38C65.3468 289.01 64.5266 283.329 65.0303 277.742Z'
      fill='url(#paint19_linear_502_813)'
    />
    <path
      d='M355.053 234.208C358.195 228.949 362.782 224.562 367.754 220.948C373.406 216.842 379.585 213.617 385.762 210.394C387.835 209.312 389.907 208.23 391.959 207.115C400.283 202.597 408.561 197.37 414.503 189.876C420.133 182.758 423.196 174.005 423.584 164.976C423.853 158.701 422.797 152.315 420.435 146.469C422.231 144.615 423.335 142.093 423.335 139.314C423.335 133.617 418.695 128.999 412.972 128.999C407.248 128.999 402.609 133.617 402.609 139.314C402.609 145.01 407.248 149.628 412.972 149.628C414.188 149.628 415.356 149.42 416.441 149.036C421.044 161.285 419.471 175.805 411.421 186.386C405.875 193.669 397.893 198.647 389.957 202.973C387.791 204.155 385.601 205.297 383.411 206.439C377.449 209.548 371.488 212.657 366.001 216.567C359.858 220.945 354.248 226.261 350.572 232.775C350.292 232.752 350.009 232.741 349.723 232.741C344 232.741 339.36 237.359 339.36 243.055C339.36 248.752 344 253.37 349.723 253.37C355.446 253.37 360.086 248.752 360.086 243.055C360.086 239.299 358.068 236.011 355.053 234.208Z'
      fill='url(#paint20_linear_502_813)'
    />
    <path
      d='M306.37 302.664C306.455 293.395 308.388 284.136 312.071 275.627C309.213 273.79 307.32 270.592 307.32 266.954C307.32 261.257 311.96 256.64 317.683 256.64C323.407 256.64 328.046 261.257 328.046 266.954C328.046 272.651 323.407 277.268 317.683 277.268C317.441 277.268 317.2 277.26 316.962 277.244C316.779 277.231 316.597 277.214 316.417 277.192C314.967 280.494 313.8 283.918 312.922 287.436C309.904 299.505 310.172 311.766 314.472 324.147C315.843 328.095 317.659 331.937 319.476 335.781C321.875 340.854 324.275 345.933 325.659 351.267C330.847 351.831 334.884 356.206 334.884 361.52C334.884 367.216 330.245 371.834 324.521 371.834C318.798 371.834 314.158 367.216 314.158 361.52C314.158 357.042 317.025 353.23 321.032 351.805C319.972 347.882 318.332 344.088 316.613 340.395C316.431 340.004 316.248 339.613 316.064 339.223C315.908 338.89 315.752 338.558 315.595 338.226L315.402 337.817C312.945 332.603 310.484 327.383 308.907 321.823C307.154 315.615 306.305 309.112 306.37 302.664Z'
      fill='url(#paint21_linear_502_813)'
    />
    <path
      d='M333.805 303.537C333.805 298.184 337.9 293.784 343.143 293.272C349.838 279.804 363.792 271.121 378.182 267.478C385.362 265.661 392.64 264.878 399.961 264.571C401.042 260.023 405.147 256.64 410.046 256.64C414.92 256.64 419.007 259.987 420.114 264.498C425.932 264.578 431.746 264.638 437.527 264.401C454.479 263.703 472.584 259.836 484.572 246.94C490.459 240.594 493.458 232.566 494.575 224.089C495.314 218.48 495.375 212.831 495.435 207.18C495.477 203.235 495.52 199.29 495.793 195.359C496.42 186.358 498.248 177.146 503.581 169.66C506.133 166.085 509.306 163.001 512.989 160.797C512.986 160.694 512.985 160.589 512.985 160.485C512.985 154.788 517.624 150.17 523.348 150.17C529.071 150.17 533.711 154.788 533.711 160.485C533.711 166.181 529.071 170.799 523.348 170.799C519.398 170.799 515.965 168.6 514.216 165.365C509.454 168.596 505.82 173.658 503.766 178.882C500.278 187.754 500.148 197.426 500.047 206.812L500.045 207.025V207.028C499.944 216.209 499.841 225.637 497.02 234.468C494.492 242.385 489.666 249.273 483.197 254.509C476.903 259.597 469.53 263.197 461.769 265.466C452.357 268.212 442.437 268.975 432.673 269.131C428.535 269.196 424.368 269.142 420.189 269.078C419.206 273.755 415.038 277.268 410.046 277.268C405.076 277.268 400.922 273.785 399.916 269.137C391.546 269.506 383.254 270.539 375.192 273.117C364.178 276.64 353.611 283.832 348.008 293.954C351.83 295.472 354.531 299.191 354.531 303.537C354.531 309.233 349.891 313.851 344.168 313.851C338.444 313.851 333.805 309.233 333.805 303.537Z'
      fill='url(#paint22_linear_502_813)'
    />
    <path
      d='M467.205 189.325C462.74 202.468 455.484 214.678 445.914 224.759C447.118 226.444 447.826 228.504 447.826 230.729C447.826 236.426 443.186 241.044 437.463 241.044C431.739 241.044 427.1 236.426 427.1 230.729C427.1 225.033 431.739 220.415 437.463 220.415C439.28 220.415 440.988 220.881 442.473 221.699C452.961 210.697 460.528 197.022 464.491 182.39C468.69 166.868 468.542 150.179 464.159 135.015C463.54 132.872 462.85 130.761 462.09 128.684C461.284 128.884 460.441 128.99 459.573 128.99C453.85 128.99 449.21 124.372 449.21 118.676C449.21 115.416 450.729 112.51 453.101 110.62C449.464 105.004 445.146 99.7207 440.111 94.7955C428.309 83.2503 413.387 75.3607 397.266 71.7235C384.127 68.7601 370.393 68.678 357.258 71.539C357.34 72.0601 357.382 72.5941 357.382 73.1379C357.382 78.8345 352.742 83.4524 347.019 83.4524C341.296 83.4524 336.656 78.8345 336.656 73.1379C336.656 67.4415 341.296 62.8235 347.019 62.8235C350.527 62.8235 353.628 64.5583 355.503 67.2131C371.654 63.5483 388.791 64.194 404.657 68.9038C421.138 73.79 436.06 83.1125 447.531 95.8885C451.096 99.8615 454.336 104.13 457.209 108.631C457.968 108.455 458.76 108.361 459.573 108.361C465.296 108.361 469.936 112.979 469.936 118.676C469.936 121.854 468.492 124.696 466.221 126.588C467.802 130.773 469.089 135.067 470.056 139.433C473.719 155.957 472.649 173.297 467.205 189.325Z'
      fill='url(#paint23_linear_502_813)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint10_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint11_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint12_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint13_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint14_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint15_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint16_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint17_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint18_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint19_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint20_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint21_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint22_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
      <linearGradient
        id='paint23_linear_502_813'
        x1={507.853}
        y1={302.824}
        x2={507.853}
        y2={518}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF4950' />
        <stop offset={1} stopColor='#FF9696' />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(UnionIcon);
export { Memo as UnionIcon };
