import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { Frame13Icon } from './Frame13Icon';
import classes from './Homepage.module.css';
import { Kesfet_Property1Default } from './Kesfet_Property1Default/Kesfet_Property1Default';
import { MingcuteNavigationFillIcon } from './MingcuteNavigationFillIcon';
import { UnionIcon } from './UnionIcon';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';

interface Props {
  className?: string;
}
/* @figmaId 1:2 */
export const Homepage: FC<Props> = memo(function Homepage(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame13}>
        <Frame13Icon className={classes.icon2} />
      </div>
      <div className={classes.misyonVizyonKimlericin}>
        <div className={classes.vector1}>
          <Vector1Icon className={classes.icon3} />
        </div>
        <div className={classes.vector2}>
          <Vector2Icon className={classes.icon4} />
        </div>
        <div className={classes.misyonumuz4}>
          <div className={classes.rectangle21}></div>
          <div className={classes.misyonumuz5}>Misyonumuz</div>
          <div className={classes.yapayZekaVeVeriBilimiAlanNdaFa}>
            Yapay Zeka ve Veri Bilimi alanında faaliyet gösteren birey ve kuruluşları tek çatı altında toplamak için
            buradayız.
          </div>
          <div className={classes.businessPlanBro1}></div>
        </div>
        <div className={classes.vizyonumuz}>
          <div className={classes.rectangle24}></div>
          <div className={classes.vizyonumuz2}>Vizyonumuz</div>
          <div className={classes.sosyalSorumlulukProjeleriIleYa}>
            {' '}
            Sosyal sorumluluk projeleri ile Yapay Zekayı birleştirmek ve sosyal sorumluluk bilinci oluşturmak için
            çalışmaktayız.
          </div>
          <div className={classes.teamWorkBro1}></div>
        </div>
        <div className={classes.kimlerIcin}>
          <div className={classes.rectangle25}></div>
          <div className={classes.kimlerIcin2}>Kimler İçin?</div>
          <div className={classes.yapayZekaAlanNdaCalSanBireyler}>
            Yapay zeka alanında çalışan bireyler, akademisyenler, öğrenciler, girişimciler, kurum ve kuruluşlar için
            hizmet vermekteyiz.
          </div>
          <div className={classes.selectingTeamBro1}></div>
        </div>
      </div>
      <div className={classes.aramZaKatL}>
        <div className={classes.rectangle20}></div>
        <div className={classes.bizeKatLarakBirlikteGelisimeKa}>
          <div className={classes.textBlock}>Bize katılarak birlikte gelişime katkıda bulun.</div>
          <div className={classes.textBlock2}>
            Bir Yapay Zeka eko-sistemi oluşturabilmek ve fikirleri hayata geçirebilmek için derneğimize gönüllü
            olabilirsin.
          </div>
        </div>
        <div className={classes.aramZaKatL2}>Aramıza Katıl!</div>
        <div className={classes.businessMergerBro1}></div>
        <Kesfet_Property1Default
          className={classes.kesfet}
          swap={{
            mingcuteNavigationFill: <MingcuteNavigationFillIcon className={classes.icon} />,
          }}
        />
      </div>
      <div className={classes.frame19}>
        <div className={classes.yapayZekaVeVeriBilimiDernegi}>
          <div className={classes.textBlock3}>Yapay Zeka ve</div>
          <div className={classes.textBlock4}>Veri Bilimi Derneği.</div>
        </div>
        <div className={classes.yapayZekaVeVeriBilimiAlanNdaki}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>Yapay Zeka ve Veri Bilimi alanındaki tüm aşamaları </span>
            <span className={classes.label2}>senin için</span>
            <span className={classes.label3}> bir araya getirdik.</span>
          </p>
        </div>
      </div>
      <div className={classes.rectangle15}></div>
      
      <div className={classes.union}>
        <UnionIcon className={classes.icon8} />
      </div>
      <Header/>
      <Footer/>
    </div>
  );
});
