import { memo, SVGProps } from 'react';

interface MingcuteNavigationFillIconProps extends SVGProps<SVGSVGElement> {
  isHovered: boolean;
}

const MingcuteNavigationFillIcon = ({ isHovered, ...props }: MingcuteNavigationFillIconProps) => (
  <svg
    preserveAspectRatio='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    style={{ transition: 'fill 500ms ease', fill: isHovered ? 'white' : '#FF4950' }}
  >
    <g clipPath='url(#clip0_502_820)'>
      <path
        d='M19.768 2.693C20.724 2.349 21.648 3.273 21.304 4.228L15.264 21.005C14.889 22.048 13.422 22.068 13.018 21.035L10.189 13.807L2.961 10.979C1.929 10.575 1.949 9.108 2.991 8.732L19.768 2.692V2.693Z'
      />
    </g>
    <defs>
      <clipPath id='clip0_502_820'>
        <rect width={24} height={24} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(MingcuteNavigationFillIcon);
export { Memo as MingcuteNavigationFillIcon };
