import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Component12Frame18.module.css';
import { NounEquality4831999OptimizedIc } from './NounEquality4831999OptimizedIc';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounEquality4831999Optimized?: ReactNode;
  };
}
/* @figmaId 357:968 */
export const Component12Frame18: FC<Props> = memo(function Component12Frame18(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.cinsiyetEsitsizligi}>Cinsiyet Eşitsizliği</div>
      <div className={classes.yapayZekaVeVeriBilimiCercevesi}>
        Yapay Zeka ve Veri bilimi çerçevesinde, cinsiyet eşitsizliği problemine çözümler geliştirmek.
      </div>
      <div className={classes.nounEquality4831999Optimized}>
        {props.swap?.nounEquality4831999Optimized || <NounEquality4831999OptimizedIc className={classes.icon} />}
      </div>
    </div>
  );
});
