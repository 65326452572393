import { memo, SVGProps, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Component11 } from "../Component11/Component11";

import classes from './Header.module.css';
import { OverlayMenu } from "../OverlayMenu/OverlayMenu";

const Header = (props: SVGProps<SVGSVGElement>) => {

    const [showOverlay, setShowOverlay] = useState(false);

    function toggleOverlay(){
        setShowOverlay(!showOverlay);
    }

    const navigate = useNavigate();
  
  function goToMain() {
    navigate("/");
    window.scroll(0, 0);
  }
  
  var fixed = document.getElementById('fixed');

  if(fixed)
    fixed.addEventListener('touchmove', function(e) {
        e.preventDefault();
  }, false);

  return (
<div className={classes.header} id='fixed'>
        <div className={classes.whatsAppImage2023524At17571} onClick={goToMain}></div>
        <div onClick={toggleOverlay} className={classes.component11Container}>
        <Component11/>
        </div>
        {showOverlay && <OverlayMenu />}
      </div>
  );
};
const Memo = memo(Header);
export { Memo as Header };