import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { NounAdvice5029283OptimizedIcon } from './NounAdvice5029283OptimizedIcon';
import { NounAiBook5446441OptimizedIcon } from './NounAiBook5446441OptimizedIcon';
import { NounCertificate4755151Optimize } from './NounCertificate4755151Optimize';
import { NounGroup5785805OptimizedIcon } from './NounGroup5785805OptimizedIcon';
import classes from './SponsorOlMobil.module.css';
import { Vector2Icon } from './Vector2Icon';
import { Vector3Icon } from './Vector3Icon';
import { Vector5Icon } from './Vector5Icon';
import { VectorIcon3 } from './VectorIcon3';

interface Props {
  className?: string;
  hide?: {
    vector?: boolean;
  };
}
/* @figmaId 346:538 */
export const SponsorOlMobil: FC<Props> = memo(function SponsorOlMobil(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.sponsorOl}>Sponsor Ol</div>
      <div className={classes.yapayZekaVeVeriBilimiDernegiNe}>
        <p className={classes.labelWrapper}>
          <span className={classes.label}>Yapay Zeka ve Veri Bilimi Derneği’ne </span>
          <span className={classes.label2}>sponsor olabilir ve destekte bulunabilirsiniz.</span>
        </p>
        <div className={classes.textBlock}>
          <p className={classes.labelWrapper2}>
            <span className={classes.label3}>Destekte bulunabileceğiniz konular;</span>
          </p>
        </div>
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon6} />
      </div>
      <div className={classes.financeBro1}></div>
      <div className={classes.frame54}>
        <div className={classes.frame49}>
          <div className={classes.frame48}>
            <div className={classes.nounCertificate4755151Optimize}>
              <NounCertificate4755151Optimize className={classes.icon7} />
            </div>
            <div className={classes.egitimlerSNavlarVeSertifikalar}>Eğitimler, sınavlar ve sertifikalar sağlamak.</div>
          </div>
          <div className={classes.text}>
            <div className={classes.textBlock4}>
              <p></p>
            </div>
            <div className={classes.textBlock5}>
              <p></p>
            </div>
          </div>
        </div>
        <div className={classes.frame50}>
          <div className={classes.nounServices5524209Optimized}>
            <div className={classes.vector3}>
              <VectorIcon3 className={classes.icon8} />
            </div>
            <div className={classes.fromTheNounProject}>from the Noun Project</div>
            <div className={classes.frame39}></div>
          </div>
          <div className={classes.servisleriEtkinlestirmekVeGeli}>Servisleri Etkinleştirmek ve Geliştirmek</div>
        </div>
        <div className={classes.frame51}>
          <div className={classes.nounGroup5785805Optimized}>
            <NounGroup5785805OptimizedIcon className={classes.icon9} />
          </div>
          <div className={classes.yapayZekaAlanNdaToplumsalKapas}>
            Yapay Zeka alanında toplumsal kapasiteyi arttırmak.
          </div>
        </div>
        <div className={classes.frame52}>
          <div className={classes.nounAiBook5446441Optimized}>
            <NounAiBook5446441OptimizedIcon className={classes.icon10} />
          </div>
          <div className={classes.seminerPanelVeKonferanslarGibi}>
            Seminer, panel ve konferanslar gibi eğitim çalışmaları yürütmek.
          </div>
        </div>
        <div className={classes.frame53}>
          <div className={classes.nounAdvice5029283Optimized}>
            <NounAdvice5029283OptimizedIcon className={classes.icon11} />
          </div>
          <div className={classes.danSmaVeCalSmaGruplarOlusturma}>Danışma ve Çalışma Grupları Oluşturmak.</div>
        </div>
      </div>
      <div className={classes.vector32}>
        <Vector3Icon className={classes.icon12} />
      </div>
      <div className={classes.vector5}>
        <Vector5Icon className={classes.icon13} />
      </div>
      <Header/>
      <Footer/>
    </div>
  );
});