import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Component12Frame16.module.css';
import { NounEarthquake2531879Optimized } from './NounEarthquake2531879Optimized';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounEarthquake2531879Optimized?: ReactNode;
  };
}
/* @figmaId 357:970 */
export const Component12Frame16: FC<Props> = memo(function Component12Frame16(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.afetDestekTeknolojileri}>Afet Destek Teknolojileri</div>
      <div className={classes.yapayZekaVeVeriBilimiDogrultus}>
        Yapay Zeka ve Veri bilimi doğrultusunda afet anında ve sonrasında destek sağlayacak teknolojiler geliştirmek.
      </div>
      <div className={classes.nounEarthquake2531879Optimized}>
        {props.swap?.nounEarthquake2531879Optimized || <NounEarthquake2531879Optimized className={classes.icon} />}
      </div>
    </div>
  );
});
