import axios from 'axios';
import qs from 'qs';
import type { FC, ReactNode } from 'react';
import React, { memo, useState, ChangeEvent,MouseEvent } from 'react';

import resets from '../../../_resets.module.css';
import classes from './SendEmailForm.module.css';
import ErrorSign from './errorSign.svg';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    name?: ReactNode;
  };
}

export const SendEmailForm: FC<Props> = memo(function SendEmailForm(props = {}) {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<boolean>(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
  const nameRegex = /^.{3,}$/; 
  const messageRegex = /^[\s\S]{50,}$/;
  
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const formattedInput = input
      .replace(/\D/g, '')
      .slice(0, 10)
      .replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3');
  
    setPhone(formattedInput);
    if(isSubmitted) setPhoneError(!phoneRegex.test(formattedInput));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setName(value);
    if(isSubmitted) setNameError(!nameRegex.test(value.trim())); // trim() is used to remove leading/trailing white spaces
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    value = value.replace(/ +(?= )/g,'');
    setMessage(value);
    if(isSubmitted) setMessageError(!messageRegex.test(value.trim())); // trim() is used to remove leading/trailing white spaces

    console.log("Message Length: ", value.length); // Prints the length of the message
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target;
    const value = inputElement.value;
  
    setEmail(value);
    const isValidEmail = emailRegex.test(value);
    if(isSubmitted) setEmailError(!isValidEmail);
  
    if (!isValidEmail) {
      inputElement.setCustomValidity('Lütfen geçerli bir e-posta adresi giriniz!');
    } else {
      inputElement.setCustomValidity('');
    }
  };

  const handleSendButton = (e: MouseEvent<HTMLButtonElement>): void => {
    setIsSubmitted(true);

    // name validation
    if (name === '' || !nameRegex.test(name.trim())) {
        setNameError(true);
    } else {
        setNameError(false);
    }

    // email validation
    if (email === '' || !emailRegex.test(email)) {
        setEmailError(true);
    } else {
        setEmailError(false);
    }

    // phone validation
    if (phone === '' || !phoneRegex.test(phone)) {
        setPhoneError(true);
    } else {
        setPhoneError(false);
    }

    // message validation
    if (message === '' || !messageRegex.test(message.trim())) {
        setMessageError(true);
    } else {
        setMessageError(false);
    }
  };

  const hasError = () => {
    return nameError || emailError || phoneError || messageError;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const toMailAddress = "info@yzvb.org";
    const formData = {
      fullname: name,
      email,
      number: phone,
      message,
      toMailAddress
    };

    try {
      const response = await axios.post('https://ronobt.com.tr/script.php', qs.stringify(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      console.log(response);
      console.log(response.data); 

      setName('');
      setEmail('');
      setPhone('');
      setMessage('');

      window.location.reload();

      if(response.status === 200)
        window.alert("Mesajınız alınmıştır!");

      setIsSubmitted(false);

    } catch (error) {
      console.error(error); 
    }

    console.log("Form was submitted");
  };

  return  (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={`${classes.formGroup} ${nameError && isSubmitted ? classes.error : ''}`}>
        {hasError() && <img src={ErrorSign} className={classes.errorIcon}/>}
          <label>Ad Soyad {nameError && isSubmitted ? "*" : ""}</label>
          <input type="text" value={name}  maxLength={35} placeholder="Ad Soyad" required onChange={(e) => handleNameChange(e)} />
        </div>
        
        <div className={`${classes.formGroup} ${emailError && isSubmitted ? classes.error : ''}`}>
          <label>E-posta {emailError && isSubmitted ? "*" : ""}</label>
          <input type="email"  value={email} maxLength={91} placeholder="E-posta" required onChange={handleEmailChange}/>
        </div>
        <div className={`${classes.formGroup} ${phoneError && isSubmitted ? classes.error : ''}`}>
          <label>Telefon Numarası {phoneError && isSubmitted ? "*" : ""}</label>
          <input type="tel" value={phone} placeholder="Telefon Numarası" required onChange={handlePhoneChange} />
        </div>
        <div className={`${classes.formGroup} ${messageError && isSubmitted ? classes.error : ''}`}>
          <label>Mesajınız {messageError && isSubmitted ? "*" : ""}</label>
          <textarea value={message} maxLength={1000} placeholder="Mesajınız" required onChange={(e) => handleMessageChange(e)} />
          <div className= {classes.textLengthContainer}>
          {`${message.length}/1000`}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <button
          type="submit"
          onClick={handleSendButton}
          >Gönder</button>
        </div>
      </form>
    </div>
  );
});
