import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import classes from './IletisimMobil.module.css';
import { NounMail5763984OptimizedIcon } from './NounMail5763984OptimizedIcon';
import { SendEmailForm } from './SendEmailForm/SendEmailForm';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';

interface Props {
  className?: string;
  hide?: {
    vector?: boolean;
  };
}
/* @figmaId 346:595 */
export const IletisimMobil: FC<Props> = memo(function IletisimMobil(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.bizeUlasN}>Bize Ulaşın</div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon6} />
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon7} />
      </div>
      <div className={classes.nounMail5763984Optimized}>
        <NounMail5763984OptimizedIcon className={classes.icon8} />
      </div>
      <Header/>
      <SendEmailForm/>
      <Footer/>
    </div>
  );
});
