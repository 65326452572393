import { memo, SVGProps } from 'react';

const Vector3Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 25 1' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M-2.66667 0C-2.66667 1.47276 -1.47276 2.66667 0 2.66667C1.47276 2.66667 2.66667 1.47276 2.66667 0C2.66667 -1.47276 1.47276 -2.66667 0 -2.66667C-1.47276 -2.66667 -2.66667 -1.47276 -2.66667 0ZM22.3333 0C22.3333 1.47276 23.5272 2.66667 25 2.66667C26.4728 2.66667 27.6667 1.47276 27.6667 0C27.6667 -1.47276 26.4728 -2.66667 25 -2.66667C23.5272 -2.66667 22.3333 -1.47276 22.3333 0ZM0 0.5L25 0.5L25 -0.5L0 -0.5L0 0.5Z'
      fill='#FF4950'
    />
  </svg>
);

const Memo = memo(Vector3Icon);
export { Memo as Vector3Icon };
