import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import classes from './GonulluOl.module.css';
import { NounHandshake5739903OptimizedI } from './NounHandshake5739903OptimizedI';
import { SendEmailForm } from './SendEmailForm/SendEmailForm';

interface Props {
  className?: string;
}
/* @figmaId 46:841 */
export const GonulluOl: FC<Props> = memo(function GonulluOl(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame13}>
        <div className={classes.mainpage}></div>
        <div className={classes.nounHandshake5739903Optimized}>
          <NounHandshake5739903OptimizedI className={classes.icon} />
        </div>
      </div>
      <div className={classes.bizeKatLN}>Bize Katılın!</div>
      <SendEmailForm className={classes.sendEmailForm}/>
      <Header/>
      <Footer/>
    </div>
  );
});
