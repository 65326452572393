import type { FC } from 'react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import classes from './HomepageMobil.module.css';
import { MingcuteNavigationFillIcon } from './MingcuteNavigationFillIcon';
import { MobileSubjects_Property1Defaul } from './MobileSubjects_Property1Defaul/MobileSubjects_Property1Defaul';
import { UnionIcon } from './UnionIcon';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';

interface Props {
  className?: string;
  hide?: {
    vector?: boolean;
  };
}
/* @figmaId 197:210 */
export const HomepageMobil: FC<Props> = memo(function HomepageMobil(props = {}) {

  const navigate = useNavigate();

  const goToBeVolunteer = () => {
    navigate('/beVolunteer');
    window.scroll(0, 0);
  };

  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.rectangle30}></div>
      <div className={classes.yapayZekaVeVeriBilimiDernegi}>
        <div className={classes.textBlock}>Yapay Zeka ve</div>
        <div className={classes.textBlock2}>Veri Bilimi Derneği.</div>
      </div>
      <div className={classes.yapayZekaVeVeriBilimiAlanNdaki}>
        <p className={classes.labelWrapper}>
          <span className={classes.label}>Yapay Zeka ve Veri Bilimi alanındaki tüm aşamaları </span>
          <span className={classes.label2}>senin için</span>
          <span className={classes.label3}> bir araya getirdik.</span>
        </p>
      </div>
      <div className={classes.union}>
        <UnionIcon className={classes.icon2} />
      </div>
      <MobileSubjects_Property1Defaul
        className={classes.mobileSubjects}
        classes={{
          businessPlanBro1: classes.businessPlanBro1,
          teamWorkBro1: classes.teamWorkBro1,
          selectingTeamBro1: classes.selectingTeamBro1,
        }}
      />
      <div className={classes.bizeKatLarakBirlikteGelisimeKa}>
        <div className={classes.textBlock3}>Bize katılarak birlikte gelişime katkıda bulun.</div>
        <div className={classes.textBlock4}>
          <p className={classes.labelWrapper2}>
            <span className={classes.label4}>
              Bir Yapay Zeka eko-sistemi oluşturabilmek ve fikirleri hayata geçirebilmek için{' '}
            </span>
            <span className={classes.label5}>derneğimize gönüllü olabilirsin.</span>
          </p>
        </div>
      </div>
      <div className={classes.aramZaKatL}>Aramıza Katıl!</div>
      <div className={classes.businessMergerBro1}></div>
      <div className={classes.frame31} onClick={goToBeVolunteer}>
        <button className={classes.gonulluOl}>Gönüllü Ol</button>
        <div className={classes.mingcuteNavigationFill}>
          <MingcuteNavigationFillIcon className={classes.icon3} />
        </div>
      </div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon8} />
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon9} />
      </div>
      <Footer/>
      <Header/>


    </div>
  );
});
