import { memo, useState } from 'react';
import type { FC, ReactNode } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';


import resets from '../../../_resets.module.css';
import { MenuGroup13 } from '../MenuGroup13/MenuGroup13';
import { MenuGroup14 } from '../MenuGroup14/MenuGroup14';
import { MenuGroup15 } from '../MenuGroup15/MenuGroup15';
import { MenuGroup16 } from '../MenuGroup16/MenuGroup16';
import { MenuGroup17 } from '../MenuGroup17/MenuGroup17';
import { NounAttendant550573OptimizedIc } from './NounAttendant550573OptimizedIc';
import { NounDog4432245OptimizedIcon } from './NounDog4432245OptimizedIcon';
import { NounEarthquake2531879Optimized } from './NounEarthquake2531879Optimized';
import { NounEquality4831999OptimizedIc } from './NounEquality4831999OptimizedIc';
import { NounPlants1731835OptimizedIcon } from './NounPlants1731835OptimizedIcon';
import classes from './ProjeKonular_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounEarthquake2531879Optimized?: ReactNode;
    nounPlants1731835Optimized?: ReactNode;
    nounEquality4831999Optimized?: ReactNode;
    nounAttendant550573Optimized?: ReactNode;
    nounDog4432245Optimized?: ReactNode;
  };
}
/* @figmaId 401:417 */
export const ProjeKonular_Property1Default: FC<Props> = memo(function ProjeKonular_Property1Default(props = {}) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
  var fixed = document.getElementById('fixedCarousel');

  if(fixed)
    fixed.addEventListener('touchmove', function(e) {
        e.preventDefault();
  }, false);


  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`} id='fixedCarousel'>
<ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: '#ff4950',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 35,
            lineHeight: 1,
            textAlign: 'center',
            width: 35,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: '#ff4950',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 35,
            lineHeight: 1,
            textAlign: 'center',
            width: 35,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            minWidth: 768, maxWidth: 992, itemsToShow: 3
          },
        ]}
        speed={400}
        easing="linear"
        autoplayDirection='forward'
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        
      <MenuGroup13
        className={classes.menuGroup13}
        swap={{
          nounDog4432245Optimized: props.swap?.nounDog4432245Optimized || (
            <NounDog4432245OptimizedIcon className={classes.icon5} />
          ),
        }}
      />
      <MenuGroup14
        className={classes.menuGroup14}
        swap={{
          nounAttendant550573Optimized: props.swap?.nounAttendant550573Optimized || (
            <NounAttendant550573OptimizedIc className={classes.icon4} />
          ),
        }}
      />
      <MenuGroup15
        className={classes.menuGroup15}
        swap={{
          nounEquality4831999Optimized: props.swap?.nounEquality4831999Optimized || (
            <NounEquality4831999OptimizedIc className={classes.icon3} />
          ),
        }}
      />
      <MenuGroup16
        className={classes.menuGroup16}
        swap={{
          nounPlants1731835Optimized: props.swap?.nounPlants1731835Optimized || (
            <NounPlants1731835OptimizedIcon className={classes.icon2} />
          ),
        }}
      />
      <MenuGroup17
        className={classes.menuGroup17}
        swap={{
          nounEarthquake2531879Optimized: props.swap?.nounEarthquake2531879Optimized || (
            <NounEarthquake2531879Optimized className={classes.icon} />
          ),
        }}
      />
      </ReactSimplyCarousel>
    </div>
  );
});
