import { memo, SVGProps } from "react";
import { useNavigate } from "react-router-dom";
import { VectorIcon2 } from "../../HomepageMobil/VectorIcon2";
import { Component11 } from "../Component11/Component11";
import { Frame33Icon } from "../Frame33Icon";
import { Frame35Icon } from "../Frame35Icon";
import { Frame36Icon } from "../Frame36Icon";
import { Vector } from "../Vector/Vector";
import { VectorIcon } from "../VectorIcon";
import { Youtube_Property1Default } from "../Youtube_Property1Default/Youtube_Property1Default";

import classes from './Footer.module.css';


const Footer = (props: SVGProps<SVGSVGElement>) => {

    const navigate = useNavigate();
  
  function goToMain() {
    navigate("/");
    window.scroll(0, 0);
  }
  
  return (
<div className={classes.frame32}>
        <div className={classes.whatsAppImage2023524At175712}></div>
        <div className={classes.dernekMerkeziAnkaraTurkiye}>
          <div className={classes.textBlock5}>Dernek Merkezi</div>
          <div className={classes.textBlock6}>
            <p className={classes.labelWrapper3}>
              <span className={classes.label6}>Ankara/Türkiye</span>
            </p>
          </div>
        </div>
        <Youtube_Property1Default
          className={classes.youtube}
          hide={{
            vector: true,
          }}
        />
        <div className={classes.yZVBDYiTakipEt}>YZVBD’yi Takip Et!</div>
        <div className={classes.frame36}>
          <Frame36Icon className={classes.icon4} />
        </div>
        <div className={classes.frame35}>
          <Frame35Icon className={classes.icon5} />
        </div>
        <div className={classes.frame34}>
          <Vector
            swap={{
              vector: <VectorIcon className={classes.icon} />,
            }}
          />
        </div>
        <div className={classes.frame33}>
          <Frame33Icon className={classes.icon6} />
        </div>
        <div className={classes.frame37}>
          <div className={classes.vector}>
            <VectorIcon2 className={classes.icon7} />
          </div>
          <div className={classes.infoYzvbdCom}>info@aistudio.com.tr</div>
        </div>
      </div>
       );
    };
    const Memo = memo(Footer);
    export { Memo as Footer };