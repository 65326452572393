import { memo, SVGProps } from 'react';


interface VectorIconProps extends SVGProps<SVGSVGElement> {
    isHovered: boolean;
  }

  const VectorIcon = ({ isHovered, ...props }: VectorIconProps) => (
  <svg preserveAspectRatio='none' viewBox='0 0 30 22'
    fill={isHovered ? 'white' : '#212121'}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    style={{ transition: 'fill 500ms ease' }}>
    <path
      d='M12 15.4721L19.785 11L12 6.52795V15.4721ZM29.34 3.8C29.535 4.50062 29.67 5.43975 29.76 6.6323C29.865 7.82484 29.91 8.85342 29.91 9.74783L30 11C30 14.2646 29.76 16.6646 29.34 18.2C28.965 19.5416 28.095 20.4062 26.745 20.7789C26.04 20.9727 24.75 21.1068 22.77 21.1963C20.82 21.3006 19.035 21.3453 17.385 21.3453L15 21.4348C8.715 21.4348 4.8 21.1963 3.255 20.7789C1.905 20.4062 1.035 19.5416 0.66 18.2C0.465 17.4994 0.33 16.5602 0.24 15.3677C0.135 14.1752 0.0899999 13.1466 0.0899999 12.2522L0 11C0 7.7354 0.24 5.3354 0.66 3.8C1.035 2.45838 1.905 1.59379 3.255 1.22112C3.96 1.02733 5.25 0.893167 7.23 0.803726C9.18 0.699378 10.965 0.654658 12.615 0.654658L15 0.565217C21.285 0.565217 25.2 0.803726 26.745 1.22112C28.095 1.59379 28.965 2.45838 29.34 3.8Z'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };