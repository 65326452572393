import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { NounAdvice5029283OptimizedIcon } from './NounAdvice5029283OptimizedIcon';
import { NounAiBook5446441OptimizedIcon } from './NounAiBook5446441OptimizedIcon';
import { NounCertificate4755151Optimize } from './NounCertificate4755151Optimize';
import { NounGroup5785805OptimizedIcon } from './NounGroup5785805OptimizedIcon';
import classes from './SponsorOl.module.css';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon2 } from './Vector2Icon2';
import { Vector3Icon2 } from './Vector3Icon2';
import { Vector5Icon } from './Vector5Icon';
import { VectorIcon2 } from './VectorIcon2';

interface Props {
  className?: string;
}
/* @figmaId 167:119 */
export const SponsorOl: FC<Props> = memo(function SponsorOl(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame13}>
        <div className={classes.mainpage}></div>
        <div className={classes.financeBro1}></div>
        <div className={classes.sponsorOl}>Sponsor Ol!</div>
        <div className={classes.yapayZekaVeVeriBilimiDernegiNe}>
          <div className={classes.textBlock}>
            Yapay Zeka ve Veri Bilimi Derneği’ne sponsor olabilir ve destekte bulunabilirsiniz.
          </div>
          <div className={classes.textBlock2}>Destekte bulunabileceğiniz konular;</div>
        </div>
        <div className={classes.vector5}>
          <Vector5Icon className={classes.icon} />
        </div>
      </div>
      <div className={classes.frame53}>
        <div className={classes.nounAdvice5029283Optimized}>
          <NounAdvice5029283OptimizedIcon className={classes.icon7} />
        </div>
        <div className={classes.danSmaVeCalSmaGruplarOlusturma}>Danışma ve Çalışma Grupları Oluşturmak.</div>
      </div>
      <div className={classes.frame49}>
        <div className={classes.frame48}>
          <div className={classes.nounCertificate4755151Optimize}>
            <NounCertificate4755151Optimize className={classes.icon8} />
          </div>
          <div className={classes.egitimlerSNavlarVeSertifikalar}>Eğitimler, sınavlar ve sertifikalar sağlamak.</div>
        </div>
        <div className={classes.text}>
          <div className={classes.textBlock3}>
            <p></p>
          </div>
          <div className={classes.textBlock4}>
            <p></p>
          </div>
        </div>
      </div>
      <div className={classes.frame51}>
        <div className={classes.nounGroup5785805Optimized}>
          <NounGroup5785805OptimizedIcon className={classes.icon9} />
        </div>
        <div className={classes.yapayZekaAlanNdaToplumsalKapas}>
          Yapay Zeka alanında toplumsal kapasiteyi arttırmak.
        </div>
      </div>
      <div className={classes.frame50}>
        <div className={classes.nounServices5524209Optimized}>
          <div className={classes.vector6}>
            <VectorIcon2 className={classes.icon10} />
          </div>
          <div className={classes.fromTheNounProject}>from the Noun Project</div>
          <div className={classes.frame39}></div>
        </div>
        <div className={classes.servisleriEtkinlestirmekVeGeli}>Servisleri Etkinleştirmek ve Geliştirmek</div>
      </div>
      <div className={classes.frame52}>
        <div className={classes.nounAiBook5446441Optimized}>
          <NounAiBook5446441OptimizedIcon className={classes.icon11} />
        </div>
        <div className={classes.seminerPanelVeKonferanslarGibi}>
          Seminer, panel ve konferanslar gibi eğitim çalışmaları yürütmek.
        </div>
      </div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon12} />
      </div>
      <div className={classes.vector22}>
        <Vector2Icon2 className={classes.icon13} />
      </div>
      <div className={classes.vector32}>
        <Vector3Icon2 className={classes.icon14} />
      </div>
      <Header/>
      <Footer/>
    </div>
  );
});
