import { memo, SVGProps } from 'react';


interface VectorIconProps extends SVGProps<SVGSVGElement> {
    isHovered: boolean;
  }

  const VectorIcon = ({ isHovered, ...props }: VectorIconProps) => (
  <svg preserveAspectRatio='none' viewBox='0 0 30 24'
    fill={isHovered ? 'white' : '#212121'}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    style={{ transition: 'fill 500ms ease' }}>
    <path
      d='M30 3.07843C28.8958 3.55882 27.7055 3.87451 26.4723 4.02549C27.7342 3.29804 28.7094 2.1451 29.1683 0.758823C27.978 1.4451 26.6587 1.92549 25.2677 2.2C24.1348 1.01961 22.543 0.333333 20.7361 0.333333C17.3662 0.333333 14.6128 2.96863 14.6128 6.22157C14.6128 6.68823 14.6702 7.14118 14.7706 7.56667C9.66539 7.31961 5.1195 4.97255 2.09369 1.41765C1.5631 2.28235 1.26195 3.29804 1.26195 4.36863C1.26195 6.41373 2.33748 8.22549 4.00096 9.2549C2.98279 9.2549 2.03633 8.98039 1.20459 8.56863V8.6098C1.20459 11.4647 3.32696 13.8529 6.13767 14.3882C5.23527 14.6246 4.2879 14.6575 3.36998 14.4843C3.75947 15.6544 4.52228 16.6782 5.55117 17.4119C6.58006 18.1455 7.8233 18.5521 9.10612 18.5745C6.93159 20.2222 4.23613 21.1128 1.46272 21.1C0.975143 21.1 0.487572 21.0725 0 21.0176C2.72467 22.6922 5.96558 23.6667 9.43595 23.6667C20.7361 23.6667 26.9455 14.6902 26.9455 6.90784C26.9455 6.64706 26.9455 6.4 26.9312 6.13921C28.1358 5.31568 29.1683 4.27255 30 3.07843Z'
    />
  </svg>
);

const Memo = memo(VectorIcon);
export { Memo as VectorIcon };