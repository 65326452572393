import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Component12Frame14.module.css';
import { NounDog4432245OptimizedIcon } from './NounDog4432245OptimizedIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounDog4432245Optimized?: ReactNode;
  };
}
/* @figmaId 357:966 */
export const Component12Frame14: FC<Props> = memo(function Component12Frame14(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.doganNVeHayvanlarNKorunmas}>
        <div className={classes.textBlock}>Doğanın ve Hayvanların</div>
        <div className={classes.textBlock2}>Korunması</div>
      </div>
      <div className={classes.yapayZekaVeVeriBilimiIleDogaVe}>
        Yapay Zeka ve Veri bilimi ile doğa ve hayvanların korunmasını sağlamak ve sürdürülebilir hale getirmek.
      </div>
      <div className={classes.nounDog4432245Optimized}>
        {props.swap?.nounDog4432245Optimized || <NounDog4432245OptimizedIcon className={classes.icon} />}
      </div>
    </div>
  );
});
