import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../../_resets.module.css';
import classes from './Component12Frame15.module.css';
import { NounPlants1731835OptimizedIcon } from './NounPlants1731835OptimizedIcon';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    nounPlants1731835Optimized?: ReactNode;
  };
}
/* @figmaId 357:969 */
export const Component12Frame15: FC<Props> = memo(function Component12Frame15(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.cevreselOlumsuzEtkilerinAzaltL}>
        <div className={classes.textBlock}>Çevresel Olumsuz Etkilerin</div>
        <div className={classes.textBlock2}> Azaltılması</div>
      </div>
      <div className={classes.nounPlants1731835Optimized}>
        {props.swap?.nounPlants1731835Optimized || <NounPlants1731835OptimizedIcon className={classes.icon} />}
      </div>
      <div className={classes.yapayZekaVeVeriBilimiTeknoloji}>
        Yapay Zeka ve Veri bilimi teknolojileri ile çevre kirliliği oluşturabilecek hususları minimum düzeye indirmek.
      </div>
    </div>
  );
});
