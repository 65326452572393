import { memo, SVGProps } from 'react';

const Group10Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 140 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx={70} cy={4} r={4} fill='#FF4950' />
    <circle cx={4} cy={4} r={4} fill='#D9D9D9' />
    <circle cx={103} cy={4} r={4} fill='#D9D9D9' />
    <circle cx={37} cy={4} r={4} fill='#D9D9D9' />
    <circle cx={136} cy={4} r={4} fill='#D9D9D9' />
  </svg>
);

const Memo = memo(Group10Icon);
export { Memo as Group10Icon };
