import { memo, SVGProps } from 'react';

const NounMail5763984OptimizedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 137 137' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M81.7228 108.659C81.1181 108.659 80.6265 108.168 80.6265 107.563C80.6265 106.958 81.1181 106.466 81.7228 106.466H82.8803C83.485 106.466 83.9766 106.958 83.9766 107.563C83.9766 108.168 83.4851 108.659 82.8803 108.659H81.7228ZM51.8099 113.641C51.2052 113.641 50.7136 113.15 50.7136 112.545C50.7136 111.94 51.2051 111.449 51.8099 111.449H105.128C105.733 111.449 106.225 111.94 106.225 112.545C106.225 113.15 105.733 113.641 105.128 113.641H51.8099ZM87.9387 108.659C87.334 108.659 86.8424 108.168 86.8424 107.563C86.8424 106.958 87.334 106.466 87.9387 106.466H111.902C112.507 106.466 112.998 106.958 112.998 107.563C112.998 108.168 112.507 108.659 111.902 108.659H87.9387ZM47.6275 111.453C48.6022 111.414 49.4279 111.197 50.1954 110.786L114.924 75.5789C116.22 74.8732 117.133 73.7203 117.574 72.4153L76.8241 65.6143L73.3747 74.77C73.182 75.2837 72.6423 75.5651 72.1201 75.4519L62.5577 73.3732L46.12 111.277C46.6108 111.402 47.117 111.462 47.6269 111.453L47.6275 111.453ZM77.6109 63.528L117.863 70.2465C117.815 69.4491 117.595 68.6494 117.191 67.9047L94.5551 26.2881C94.049 25.3584 93.3151 24.626 92.458 24.1237L77.6114 63.5288L77.6109 63.528ZM44.1477 110.343L60.3835 72.9007L19.2346 63.9544C19.1903 64.9445 19.4058 65.9582 19.9104 66.8862L42.5487 108.507C42.9524 109.251 43.5043 109.868 44.1473 110.342L44.1477 110.343ZM19.746 61.8233L71.6631 73.1129L90.3936 23.3995C89.2231 23.2275 87.9908 23.4179 86.8815 24.0211L22.1764 59.2164C21.0655 59.8196 20.236 60.75 19.7452 61.8248L19.746 61.8233Z'
      fill='url(#paint0_linear_632_982)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_632_982'
        x1={68.5462}
        y1={23.3389}
        x2={68.5462}
        y2={113.641}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF9696' />
        <stop offset={1} stopColor='#FF4950' />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(NounMail5763984OptimizedIcon);
export { Memo as NounMail5763984OptimizedIcon };
