import type { FC } from 'react';
import { memo } from 'react';

import resets from '../../_resets.module.css';
import { Footer } from '../Shared/Footer/Footer';
import { Header } from '../Shared/Header/Header';
import { NounAttendant550573OptimizedIc } from './NounAttendant550573OptimizedIc';
import { NounDog4432245OptimizedIcon } from './NounDog4432245OptimizedIcon';
import { NounEarthquake2531879Optimized } from './NounEarthquake2531879Optimized';
import { NounEquality4831999OptimizedIc } from './NounEquality4831999OptimizedIc';
import { NounPlants1731835OptimizedIcon } from './NounPlants1731835OptimizedIcon';
import classes from './Projelerimiz.module.css';
import { Vector1Icon } from './Vector1Icon';
import { Vector2Icon } from './Vector2Icon';
import { Vector3Icon } from './Vector3Icon';
import { Vector5Icon } from './Vector5Icon';

interface Props {
  className?: string;
}
/* @figmaId 187:486 */
export const Projelerimiz: FC<Props> = memo(function Projelerimiz(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.frame13}>
        <div className={classes.mainpage}></div>
        <div className={classes.projeAlanlarMZ}>Proje Alanlarımız.</div>
        <div className={classes.yapayZekaVeVeriBilimiIleSosyal}>
          <p className={classes.labelWrapper}>
            <span className={classes.label}>Yapay Zeka ve Veri Bilimi ile </span>
            <span className={classes.label2}>sosyal sorumlulu</span>
            <span className={classes.label3}>k çerçevesinde odaklandığımız ana proje konularımız;</span>
          </p>
        </div>
        <div className={classes.vector5}>
          <Vector5Icon className={classes.icon} />
        </div>
      </div>
      <div className={classes.frame14}>
        <div className={classes.doganNVeHayvanlarNKorunmas}>
          <div className={classes.textBlock}>Doğanın ve Hayvanların</div>
          <div className={classes.textBlock2}>Korunması</div>
        </div>
        <div className={classes.yapayZekaVeVeriBilimiIleDogaVe}>
          Yapay Zeka ve Veri bilimi ile doğa ve hayvanların korunmasını sağlamak ve sürdürülebilir hale getirmek.
        </div>
        <div className={classes.nounDog4432245Optimized}>
          <NounDog4432245OptimizedIcon className={classes.icon4} />
        </div>
      </div>
      <div className={classes.frame17}>
        <div className={classes.dezavantajlGruplarNDesteklenme}>
          <div className={classes.textBlock3}>Dezavantajlı Grupların</div>
          <div className={classes.textBlock4}>Desteklenmesi</div>
        </div>
        <div className={classes.yapayZekaVeVeriBilimiImkanlarI}>
          Yapay Zeka ve Veri bilimi imkanları ile dezavantajlı gruplara fayda sağlamak ve gündelik yaşama entegre
          olmalarını kolaylaştırmak.
        </div>
        <div className={classes.nounAttendant550573Optimized}>
          <NounAttendant550573OptimizedIc className={classes.icon5} />
        </div>
      </div>
      <div className={classes.frame18}>
        <div className={classes.cinsiyetEsitsizligi}>
          <div className={classes.textBlock5}>Cinsiyet</div>
          <div className={classes.textBlock6}>Eşitsizliği</div>
        </div>
        <div className={classes.yapayZekaVeVeriBilimiCercevesi}>
          Yapay Zeka ve Veri bilimi çerçevesinde, cinsiyet eşitsizliği problemine çözümler geliştirmek.
        </div>
        <div className={classes.nounEquality4831999Optimized}>
          <NounEquality4831999OptimizedIc className={classes.icon6} />
        </div>
      </div>
      <div className={classes.frame15}>
        <div className={classes.cevreselOlumsuzEtkilerinAzaltL}>
          <div className={classes.textBlock7}>Çevresel Olumsuz Etkilerin</div>
          <div className={classes.textBlock8}> Azaltılması</div>
        </div>
        <div className={classes.yapayZekaVeVeriBilimiTeknoloji}>
          Yapay Zeka ve Veri bilimi teknolojileri ile çevre kirliliği oluşturabilecek hususları minimum düzeye indirmek.
        </div>
        <div className={classes.nounPlants1731835Optimized}>
          <NounPlants1731835OptimizedIcon className={classes.icon7} />
        </div>
      </div>
      <div className={classes.frame16}>
        <div className={classes.afetDestekTeknolojileri}>
          <div className={classes.textBlock9}>Afet Destek</div>
          <div className={classes.textBlock10}>Teknolojileri</div>
        </div>
        <div className={classes.yapayZekaVeVeriBilimiDogrultus}>
          Yapay Zeka ve Veri bilimi doğrultusunda afet anında ve sonrasında destek sağlayacak teknolojiler geliştirmek.
        </div>
        <div className={classes.nounEarthquake2531879Optimized}>
          <NounEarthquake2531879Optimized className={classes.icon8} />
        </div>
      </div>
      <div className={classes.vector1}>
        <Vector1Icon className={classes.icon9} />
      </div>
      <div className={classes.vector2}>
        <Vector2Icon className={classes.icon10} />
      </div>
      <div className={classes.vector3}>
        <Vector3Icon className={classes.icon11} />
      </div>
      <div className={classes.cyborgBro1}></div>
      <Header/>
      <Footer/>
    </div>
  );
});
