import { memo, useState } from 'react';
import type { FC } from 'react';

import resets from '../../../_resets.module.css';
import { IcRoundDownloadIcon } from './IcRoundDownloadIcon';
import classes from './TuzuguIndir_Property1Default.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 153:105 */
export const TuzuguIndir_Property1Default: FC<Props> = memo(function TuzuguIndir_Property1Default(props = {}) {
  const [fill, setFill] = useState('#ff4950');
  const [isClicked, setIsClicked] = useState(false);

  async function downloadAgreement(){
    if (!isClicked) {
      // Perform your button click logic
      console.log('Button clicked!');
      
      // Update the clicked state
      setIsClicked(true);

      try {
      window.open('https://ronobt.com.tr/res/YZVBD-Tüzük.docx');
      } catch (error) {
        window.alert("There was an unexpected error!") // Handle any error that occurs during the request
      }
    }
  }

  return (
    <button className={`${resets.clapyResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
    onMouseEnter={() => setFill('#FFFFFF')}
    onMouseLeave={() => setFill('#ff4950')}
    onClick={downloadAgreement}>
      <div className={classes.icRoundDownload}>
        <IcRoundDownloadIcon className={classes.icon} fill={fill}/>
      </div>
      <div className={classes.tuzuguIndir} >Tüzüğü İndir </div>
    </button>
  );
});
